import { useContext, useEffect, useState } from "react";
import { INVESTMENT_STATE, Investment } from "../../../types/investment_types";
import { AppContext } from "../direct-router";
import { Document, USER_STATE } from "../../../types/generic_types";
import API_CLIENT from "../../../utility/api-client";
import YouSignDialog from "../../../components/dialogs/yousign-dialog";
import IVDrawer from "../../../components/template-components/iv-drawer";
import React from "react";
import VideoIdent from "./videoident";
import { InvestmentStatusChipV2 } from "../../../components/data-display/investment-status-chip";
import AmountUtils from "../../../utility/amount-utils";
import { CopyTextV2 } from "../../../components/data-display/copy-text";
import IBAN from "iban";
import { PROVIDER } from "../../../types/fund_types";
import LoadingScreen from "../../../components/loading-screen";
import PdfPreviewer from "./pdf-preview-document";
import { PrimaryButtonV2 } from "../../../components/input-elements/buttons";
import { DashboardType } from "../../../types/dashboard_types";

interface InvestmentDetailsDrawerProps {
  setInvestmentId: (id: string | null) => void;
  investment_id: string | null;
  dashboard: DashboardType;
}

export default function InvestmentDetailsDrawer({
  investment_id,
  setInvestmentId,
  dashboard,
}: InvestmentDetailsDrawerProps) {
  const [signing_open, setSigningOpen] = useState(false);
  const open = investment_id != null;
  const app_context = useContext(AppContext);
  const [investment, setInvestment] = useState<Investment>();
  const [docs, setDocs] = useState<Document[]>();
  const [cl_docs, setClDocs] = useState<Document[]>();
  const campaign = app_context.funds
    .map((fund) => fund.cashlinkCampaigns)
    .flat()
    .find((c) => c.cashlinkId == investment?.campaignId);
  const needs_videoident = app_context.userState == USER_STATE.ONBOARDED_1;

  var co_investment = dashboard.coInvestments.find(
    (i) => i.id == investment_id
  );

  useEffect(() => {
    if (investment_id == null) return;
    API_CLIENT.getInvestment(
      app_context.token,
      investment_id,
      (investment) => setInvestment(investment),
      () => console.log("no investment found"),
      (error) => console.log(error)
    );
    API_CLIENT.getInvestmentDocs(app_context.token, investment_id, (docs) => {
      setDocs(docs);
    });
    API_CLIENT.getInvestmentDocsV1(app_context.token, investment_id, (docs) =>
      setClDocs(docs)
    );
  }, [investment_id]);

  useEffect(() => {
    if (!investment || !investment_id) return;
  }, [investment, investment_id]);

  if (co_investment) {
    return (
      <IVDrawer
        large={false}
        open={investment_id != null}
        close={() => setInvestmentId(null)}
      >
        <div className=" p-8 grid gap-8">
          <div className="flex">
            <div>
              <h1 className="text-xl font-medium leading-5">
                Investitionsdetails
              </h1>
              <p className="text-xst text-primary_light">#{co_investment.id}</p>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-xs text-gray-500 leading-3">Betrag</p>
              <p className="text-lg font-medium">
                {co_investment.amount.toLocaleString("de-de")},00 €
              </p>
            </div>
          </div>
          <div>
            {co_investment.documents.map((doc) => (
              <PdfPreviewer name={doc.fileName.split(".")[0]} url={doc.url} />
            ))}
          </div>
        </div>
      </IVDrawer>
    );
  }

  return (
    <div>
      {signing_open ? (
        <YouSignDialog
          signature_link={investment?.yousign_signature_link}
          is_open={signing_open}
          onClose={() => {
            setSigningOpen(false);
          }}
          onSuccess={() => {
            API_CLIENT.waitingForYousignInvestment(
              app_context.token,
              investment_id!,
              (investment: Investment) => {
                setInvestment(investment);
              },
              (err) => console.log(err)
            );
          }}
        />
      ) : null}

      <IVDrawer
        large
        open={investment_id != null}
        close={() => setInvestmentId(null)}
      >
        {investment ? (
          <div className=" p-8 grid gap-8">
            <div className="flex">
              <div>
                <h1 className="text-xl font-medium leading-5">
                  Investitionsdetails
                </h1>
                <h2 className="text-xs text-gray-500">#{investment.id}</h2>
              </div>

              <div className="flex-grow" />
              <div
                className="relative w-8 h-8 bg-gray-100 rounded-full flex justify-center items-center"
                onClick={() => setInvestmentId(null)}
              >
                <div className="absolute h-[2px] w-4 bg-gray-400 rotate-45" />
                <div className="absolute h-[2px] w-4 bg-gray-400 -rotate-45" />
              </div>
            </div>
            {needs_videoident ? <VideoIdent /> : null}
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-xs text-gray-500 leading-3">Betrag</p>
                <p className="text-lg font-medium">
                  {investment.amount.toLocaleString("de-de")},00 €
                </p>
              </div>
              <div className="grid gap-1">
                <p className="text-xs text-gray-500 leading-3">Status</p>
                <InvestmentStatusChipV2 state={investment.state} />
              </div>
              <div className="grid gap-1">
                <p className="text-xs text-gray-500 leading-3">
                  Zahlungsbetrag
                </p>
                <p className="text-lg font-medium">
                  {AmountUtils.toString(investment.investmentAmount)}
                </p>
              </div>
              {investment.signedAt ? (
                <div className="col-span-1">
                  <p className="text-xs text-gray-500 leading-3">Datum</p>
                  <p className="text-lg font-medium">
                    {new Date(investment.signedAt).toLocaleDateString("de-de")}
                  </p>
                </div>
              ) : null}
              <div className="col-span-2">
                <p className="text-xs text-gray-500 leading-3">Produkt</p>
                <p className="text-lg font-medium">{investment.fundName}</p>
              </div>
            </div>
            <div className="grid gap-2">
              <p className="text-sm">Dokumente</p>
              <div className="flex flex-wrap gap-2">
                {campaign?.documents.map((doc) => (
                  <PdfPreviewer name={doc.name} url={doc.location} />
                ))}
              </div>
            </div>
            <div className="grid gap-2">
              <p className="text-sm">Investitionsdokumente</p>
              <div className="flex flex-wrap gap-2">
                {docs?.map((doc) => (
                  <PdfPreviewer name={doc.description} url={doc.location} />
                ))}
                {cl_docs?.map((cl_doc) => (
                  <PdfPreviewer
                    name={cl_doc.name.split(".")[0].replaceAll("_", " ")}
                    url={cl_doc.location}
                  />
                ))}
              </div>
            </div>

            <div className="grid gap-4">
              {investment.state == INVESTMENT_STATE.SIGNATURE_PENDING ? (
                <div>
                  {" "}
                  <div className="text-base bg-green-100 text-green-800 p-4 grid gap-2 rounded-xl">
                    <h2>Unterzeichnen deiner Beitrittserklärung</h2>
                    <p className="text">
                      Wir haben dir deine Beitrittserklärung erstellt. Du kannst
                      diese jetzt ganz einfach über unseren Partner YouSign
                      unterzeichnen. Klicke dazu auf den "Jetzt
                      signieren"-Button und folge den Anweisungen.
                    </p>
                  </div>
                </div>
              ) : investment.state == INVESTMENT_STATE.ACCEPTED ? (
                <div>
                  <div className="grid gap-4">
                    <div className="bg-green-100 text-green-800 p-4 rounded-xl">
                      <p className="text-left text-sm">
                        Wir haben Deine Investition angenommen. Überweise bitte
                        den oben stehenden Betrag unter Angabe des
                        Referenztextes innerhalb von 14 Tagen auf das Bankkonto
                        der Emittentin, um die Investition abzuschließen.
                      </p>
                    </div>
                    <div className="grid gap-2">
                      <div className="text-left">
                        <p className="text-xs text-gray-700">Kontoinhaber</p>
                        <CopyTextV2
                          text={
                            campaign?.provider == PROVIDER.CASHLINK
                              ? investment.paymentDetails.bankAccount
                                  .accountHolder
                              : campaign?.bankAccountHolder
                          }
                        />
                      </div>
                      <div className="text-left">
                        <p className="text-xs text-gray-700">IBAN</p>
                        <CopyTextV2
                          text={
                            campaign?.provider == PROVIDER.CASHLINK
                              ? IBAN.printFormat(
                                  investment.paymentDetails.bankAccount.iban
                                )
                              : campaign?.bankAccountIban
                              ? IBAN.printFormat(campaign?.bankAccountIban)
                              : ""
                          }
                        />
                      </div>
                      <div className="text-left">
                        <p className="text-xs text-gray-700">BIC</p>
                        <CopyTextV2
                          text={
                            campaign?.provider == PROVIDER.CASHLINK
                              ? investment.paymentDetails.bankAccount.bic
                              : campaign?.bankAccountBic
                          }
                        />
                      </div>
                      <div className="text-left">
                        <p className="text-xs text-gray-700">Referenztext</p>
                        <CopyTextV2
                          text={
                            campaign?.provider == PROVIDER.CASHLINK
                              ? investment.paymentDetails?.paymentCode
                                ? investment.paymentDetails?.paymentCode
                                : ""
                              : investment.id.slice(0, 10)
                          }
                        />
                      </div>
                    </div>
                    <div>
                      <div>
                        <div className="p-4 bg-orange-100 text-orange-800 rounded-lg">
                          <p className="text-sm">
                            Sobald deine Zahlung bei uns eingetroffen ist,
                            werden wir dich per E-Mail benachrichtigen. Den
                            aktuellen Status deiner Investition kannst du auch
                            immer in deinem{" "}
                            <a href="/portal/dashboard">Dashboard</a> einsehen.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {investment.state == INVESTMENT_STATE.WAITING_FOR_ACCEPTANCE ? (
                <div>
                  <div>
                    <div className="text-base bg-green-100 text-green-800 p-4 rounded-xl">
                      <p>Wir prüfen aktuell Deine Investition.</p>
                      <p>
                        Innerhalb der nächsten Tage erhältst Du eine E-Mail mit
                        einem Link zu den Zahlungsdetails von uns.
                      </p>{" "}
                      <p>
                        Den Status deiner Investition kannst Du jederzeit bequem
                        unter Investitionen in deinem{" "}
                        <a href="/portal/dashboard">Dashboard</a> einsehen.
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}
              {investment.state == INVESTMENT_STATE.SIGNATURE_PENDING ? (
                <div>
                  <div>
                    <PrimaryButtonV2
                      onClick={() => {
                        setSigningOpen(true);
                      }}
                    >
                      Jetzt signieren
                    </PrimaryButtonV2>
                  </div>
                </div>
              ) : null}
              {investment.state == INVESTMENT_STATE.SIGNED ||
              investment.state == INVESTMENT_STATE.WAITING_FOR_YOUSIGN ? (
                <div>
                  <div>
                    <div className="text-base bg-green-100 text-green-800 p-4 rounded-xl grid gap-4">
                      <h2>Vielen Dank für deine Investition.</h2>
                      <p>
                        Wir prüfen deine Investition innerhalb der nächsten Tage
                        und melden uns per E-Mail bei dir, sobald wir diese
                        akzeptiert haben. Den aktuellen Status deiner
                        Investition kannst du jederzeit im Dashboard einsehen.
                      </p>
                      <p>
                        <b>
                          Wichtig: Du musst dich legitimieren, bevor wir deine
                          Investition annehmen können.
                        </b>
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          <LoadingScreen className="h-full w-full" />
        )}
      </IVDrawer>
    </div>
  );
}
