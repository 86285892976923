import { TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import {
  BootstrapInput,
  BootstrapInput2,
} from "../../../../components/hook-form-inputs";
import {
  SecondaryButton,
  SecondaryButtonV2,
} from "../../../../components/input-elements/buttons";
import MultistepFormButtons, {
  MultistepFormButtonsV2,
} from "../../../../components/vertical-multistep-form/multistep-form-buttons";
import { InvestmentContextFull } from "../investment-process-full";
import { AppContext } from "../../direct-router";

export default function InvestmentAmount() {
  const investment_context = useContext(InvestmentContextFull);
  const app_context = useContext(AppContext);

  var initial_amount = Number(
    localStorage.getItem(
      `investment-amount-${investment_context.campaign?.cashlinkId}`
    )
  );
  if (initial_amount == 0) initial_amount = 20000;
  const [amount, setAmount] = useState(initial_amount);
  const MIN_AMOUNT = 10000;
  const agio = investment_context.campaign?.agio;
  const [amount_err, setAmountErr] = useState(false);

  useEffect(() => {
    if (amount % 1000 != 0 || amount < 10000) {
      setAmountErr(true);
      return;
    }
    setAmountErr(false);
  }, [amount]);

  const handleSubmit = (e: React.SyntheticEvent) => {
    console.log("set investment amount");
    e.preventDefault();
    localStorage.setItem(
      `investment-amount-${investment_context.campaign?.cashlinkId}`,
      amount.toString()
    );

    investment_context.setLoading(true);

    setTimeout(() => {
      if (amount == investment_context.investment?.amount) {
        console.log("amount didnt change");
        investment_context.setForceRedraw();
      } else {
        investment_context.setAmount(amount);
        if (initial_amount == amount && !app_context.authenticated) {
          investment_context.setForceRedraw();
        }
      }
    }, 1000);
  };

  return (
    <form onSubmit={handleSubmit} className="grid gap-16">
      <div className="grid gap-8">
        <p className="text-3xl font-medium text-primary font-headline">
          Gib hier deinen gewünschten Anlagebetrag ein
        </p>

        <div className="grid gap-4">
          <div className="grid gap-2 border rounded-xl">
            <NumberFormat
              className="w-full"
              value={amount || ""}
              inputProps={{
                style: {
                  fontSize: "24px",
                  fontWeight: 600,
                  padding: "10px 15px",
                },
              }}
              customInput={BootstrapInput2}
              suffix={" €"}
              type="text"
              decimalSeparator={","}
              thousandSeparator={"."}
              onValueChange={(value: any) => {
                setAmount(Number(value.value));
              }}
            />
            <p
              className={`${
                amount_err ? "text-red-500" : "test-primary"
              }  text-sm mx-3 mb-2  `}
            >
              Gib hier den Betrag ein, den Du investieren möchtest. Der Betrag
              muss größer als 10.000 € und durch 1.000 teilbar sein.{" "}
              {agio ? (
                <span className="text-primary">
                  Zuzüglich fallen 5% <b>({(amount * agio) / 100} €)</b> Agio
                  an.
                </span>
              ) : null}
            </p>
          </div>
          <div className="grid gap-2">
            <p className="text-primary text-sm">
              Oder wähle eine der folgenden Investitionsgrößen
            </p>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-2 whitespace-nowrap">
              <SecondaryButtonV2 onClick={() => setAmount(MIN_AMOUNT)}>
                {MIN_AMOUNT.toLocaleString("de-de")} €
              </SecondaryButtonV2>
              <SecondaryButtonV2 onClick={() => setAmount(MIN_AMOUNT * 2)}>
                {(MIN_AMOUNT * 2).toLocaleString("de-de")} €
              </SecondaryButtonV2>
              <SecondaryButtonV2 onClick={() => setAmount(MIN_AMOUNT * 10)}>
                {(MIN_AMOUNT * 10).toLocaleString("de-de")} €
              </SecondaryButtonV2>
              <SecondaryButtonV2 onClick={() => setAmount(MIN_AMOUNT * 20)}>
                {(MIN_AMOUNT * 20).toLocaleString("de-de")} €
              </SecondaryButtonV2>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <MultistepFormButtonsV2
          disabled={amount_err == true}
          back={false}
          backFunction={() => null}
        />
      </div>
    </form>
  );
}
