import { Box, LinearProgress, Typography } from "@mui/material";
import { motion } from "framer-motion";
import React, { useContext } from "react";
import { InvestmentContextFull } from "../../pages/direct/investment-process-full/investment-process-full";
import theme from "../../theme";
import PrimaryButton, {
  PrimaryButtonV2,
  SecondaryButton,
  SecondaryButtonV2,
} from "../input-elements/buttons";

type step = {
  name: string;
  value: number;
};

type VerticalStepperProps = {
  totalSteps: number;
  style?: React.CSSProperties;
  blockTo?: number;
  activeStep: number;
  highestStep: number;
  labels: string[];
  returnOnFirstStep?: boolean;
  onStepClick: (step: number) => void;
  hideFirstStep?: boolean;
  alignTop?: boolean;
  loading?: boolean;
};

export default function VerticalStepper({
  totalSteps,
  style,
  activeStep,
  highestStep,
  labels,
  blockTo,
  returnOnFirstStep,
  onStepClick,
  hideFirstStep,
  alignTop,
}: VerticalStepperProps) {
  const activeStepBlocked = blockTo ? activeStep >= blockTo : false;
  const firstStepBlock = activeStep == 0 && !returnOnFirstStep;
  const showBackArrow = !activeStepBlocked && !firstStepBlock;
  const progress = (activeStep / totalSteps) * 100;
  return (
    <>
      <Box
        sx={{
          display: {
            xs: "none",
            md: "flex",
          },
        }}
        style={{
          justifyContent: "flex-end",
          flexDirection: "row",
          ...style,
        }}
      >
        <div
          style={{
            position: "sticky",
            top: "0",
            height: "fit-content",
            display: "flex",
            flexDirection: "column",
            textAlign: "right",
            justifyContent: "flex-start",
            paddingTop: "150px",
            paddingBottom: "50px",
          }}
        >
          {labels.map((label, i) => {
            if (hideFirstStep && i == 0) return null;
            return (
              <StepperEntry
                onStepClick={(i) => onStepClick(i)}
                text={label}
                step={i}
                activeStep={activeStep}
                highestStep={highestStep}
                blockTo={blockTo}
              />
            );
          })}
        </div>
        <div
          style={{ height: "100%", width: "0px", backgroundColor: "lightgray" }}
        />
      </Box>
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <Box sx={{ display: { xs: "block", md: "none" } }}>
          <div
            id="header"
            style={{
              height: "60px",
              width: "calc(100vw - 20px)",
              display: "flex",
              alignItems: "center",
              padding: "0 10px",
            }}
          >
            {showBackArrow ? (
              <img
                src="https://ik.imagekit.io/inventure/App/static/return_0VTTTSJd6a.png?updatedAt=1641415123794"
                style={{ height: "30px" }}
                onClick={() => {
                  onStepClick(activeStep - 1);
                }}
              />
            ) : null}
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LinearProgress
                variant="determinate"
                value={progress}
                style={{ width: "90%" }}
              />
            </div>
          </div>
        </Box>
      </Box>
    </>
  );
}

export function VerticalStepperV2({
  totalSteps,
  style,
  activeStep,
  highestStep,
  labels,
  blockTo,
  returnOnFirstStep,
  onStepClick,
  hideFirstStep,
  loading,
}: VerticalStepperProps) {
  const investment_context = useContext(InvestmentContextFull);
  const activeStepBlocked = blockTo ? activeStep >= blockTo : false;
  const firstStepBlock = activeStep == 0 && !returnOnFirstStep;
  const showBackArrow = !activeStepBlocked && !firstStepBlock;
  const progress = (activeStep / totalSteps) * 100;
  const amount = investment_context.amount;
  return (
    <>
      <Box
        sx={{
          display: {
            xs: "none",
            md: "flex",
          },
        }}
        style={{
          ...style,
        }}
        className=" flex-col overflow-hidden gap-8"
      >
        {/* <div className="w-full grid gap-2 bg-primary bg-gradient-to-r from-primary to-primary_light px-8 py-4 rounded-2xl border">
          {!loading ? (
            <>
              <div className="flex gap-2">
                <div className="h-6 w-6 mt-1 bg-cover bg-[url('https://ik.imagekit.io/inventure/App/Icons/icons8-investment-48_Aic6j4jOcz.png?updatedAt=1704734351787')]" />

                <div>
                  <Typography variant="body1" className="text-xs text-white ">
                    Zielinvestment
                  </Typography>
                  <div className="w-full bg-white"></div>
                  <Typography
                    variant="body1"
                    className="text-xs text-white font-semibold"
                  >
                    {investment_context.fund?.name}
                  </Typography>
                </div>
              </div>
              <div>
                <div className="flex gap-2">
                  {/* <img
                className="h-5 w-5 mr-1 mt-1"
                src="https://ik.imagekit.io/inventure/App/Icons/icons8-bill-96_HmivMHRqM.png?updatedAt=1687291932130"
              /> 
                  <div>
                    <p className="ml-[2px] text-xl text-white font-medium">
                      {amount != 0 && amount != undefined
                        ? "€ " + amount?.toLocaleString("de-de") + ""
                        : " "}
                    </p>
                  </div>
                </div>
              </div>{" "}
            </>
          ) : (
            <div className="grid gap-2">
              <div className="animate-pulse w-2/3 h-5 bg-gray-50 opacity-30 rounded-lg" />
              <div className="animate-pulse w-full h-5 bg-gray-50 opacity-50 rounded-lg" />
            </div>
          )}
        </div> */}
        <div className="gap-5 bg-[#E2EAF2] text-primary rounded-2xl grid px-8 py-4 opacity-100">
          <p className="w-full text-left font-semibold text-md">
            Investitionsschritte
          </p>
          {labels.map((label, i) => {
            if (loading)
              return (
                <div
                  key={i}
                  className="animate-pulse h-16 w-full bg-gray-400 rounded-lg opacity-30"
                />
              );
            if (hideFirstStep && i == 0) return null;
            return (
              <StepperEntryV2
                key={i}
                onStepClick={(i) => onStepClick(i)}
                text={label}
                step={i}
                activeStep={activeStep}
                highestStep={highestStep}
                blockTo={blockTo}
              />
            );
          })}
          <div style={{ height: "100%", width: "0px" }} />{" "}
        </div>
        <div className="w-full rounded-xl overflow-hidden flex relative mb-4">
          <div className=" z-10  w-full h-full grid gap-4">
            <div className="flex gap-4 items-center">
              <img
                className="h-16 aspect-square object-cover border rounded-full"
                src="https://ik.imagekit.io/inventure/Staff/6282151cad5b6b65b1b65a75_venture-capital-samuel-gassauer%20(1)_2PorJWJLS.jpg?updatedAt=1715936745398"
              />
              <div>
                <p className="text-sm h-5 leading-none">Benötigst du Hilfe?</p>
                <p className="text-md font-medium leading-[18px]">
                  Unsere Experten helfen dir gerne weiter.
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <button
                onClick={() => {
                  (window as any).HubSpotConversations.widget.open();
                }}
                className="h-10 eading-none flex gap-2 items-center border px-4 rounded-full border-primary text-sm hover:bg-slate-100"
              >
                Jetzt Kontakt aufnehmen
                <img
                  src="https://img.icons8.com/?size=512&id=7789&format=png"
                  className="h-6"
                />
              </button>
            </div>
          </div>
        </div>
      </Box>
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <Box sx={{ display: { xs: "block", md: "none" } }}>
          <div
            id="header"
            style={{
              height: "60px",
              width: "calc(100vw - 20px)",
              display: "flex",
              alignItems: "center",
              padding: "0 10px",
            }}
          >
            {showBackArrow ? (
              <img
                src="https://ik.imagekit.io/inventure/App/static/return_0VTTTSJd6a.png?updatedAt=1641415123794"
                style={{ height: "30px" }}
                onClick={() => {
                  onStepClick(activeStep - 1);
                }}
              />
            ) : null}
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LinearProgress
                variant="determinate"
                value={progress}
                style={{ width: "90%" }}
              />
            </div>
          </div>
        </Box>
      </Box>
    </>
  );
}

type StepperEntryProps = {
  text: string;
  step: number;
  blockTo?: number;
  activeStep: number;
  highestStep: number;
  onStepClick: (step: number) => void;
  done?: boolean;
};
function StepperEntry({
  text,
  step,
  activeStep,
  highestStep,
  blockTo,
  onStepClick,
  done,
}: StepperEntryProps) {
  const spring = {
    type: "spring",
    stiffness: 140,
    damping: 30,
  };
  var active = activeStep == step;
  if (blockTo == undefined) blockTo = 0;
  var blocked = step < blockTo;

  return (
    <>
      <motion.div
        transition={spring}
        onClick={() => {
          if (!blocked) {
            onStepClick(step);
          }
        }}
        style={{
          cursor: blocked ? "" : highestStep >= step ? "pointer" : "",
          display: "flex",
          justifyContent: "flex-end",
          zIndex: 100,
          alignItems: "center",
          height: "50px",
          color: theme.palette.primary.dark,
        }}
      >
        {done ? <div className="w-6 h-6 bg-black" /> : null}
        <Typography
          variant="h6"
          sx={{ display: { xs: "none", md: "block" } }}
          style={{
            fontWeight: active ? 700 : 500,
            color: blocked
              ? "gray"
              : step <= highestStep || active
              ? "black"
              : "lightgray",
            marginRight: active ? "-5px" : "0px",
          }}
        >
          {text}
        </Typography>
        <motion.div
          transition={spring}
          layout
          style={{
            height: active ? "20px" : "10px",
            width: active ? "20px" : "10px",
            borderRadius: "50%",
            backgroundColor: blocked
              ? "gray"
              : active
              ? theme.palette.primary.light
              : step < highestStep
              ? theme.palette.primary.light
              : "lightgray",
            marginLeft: "16px",
            marginRight: active ? "-10px" : "-5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <motion.div
            transition={spring}
            layout
            style={{
              height: active ? "14px" : "0px",
              width: active ? "14px" : "0px",
              borderRadius: "50%",
              backgroundColor: theme.palette.primary.main,
            }}
          />
        </motion.div>
      </motion.div>
    </>
  );
}

function StepperEntryV2({
  text,
  step,
  activeStep,
  highestStep,
  blockTo,
  onStepClick,
  done,
}: StepperEntryProps) {
  const spring = {
    type: "spring",
    stiffness: 140,
    damping: 30,
  };
  var active = activeStep == step;
  if (blockTo == undefined) blockTo = 0;
  var blocked = step < blockTo;
  var passed = highestStep >= step;

  return (
    <>
      <div
        className="flex gap-4 group"
        onClick={() => {
          if (!blocked) {
            onStepClick(step);
          }
        }}
        style={{
          cursor: blocked ? "" : passed || active ? "pointer" : "",

          zIndex: 100,
          alignItems: "center",
          height: "50px",
          color: theme.palette.primary.dark,
        }}
      >
        {" "}
        <div className="relativd h-4 w-4">
          <div
            className={`absolute h-4 w-4 ${
              active ? "border" : ""
            } flex justify-center items-center rounded-2xl border-[#59B2F6]`}
          >
            <div
              className={`h-2 w-2 ${
                passed ? "group-hover:h-4 group-hover:w-4" : ""
              } transition-all duration-300 ${
                passed || active ? "bg-[#59B2F6]" : "bg-[#9ACBFA]"
              } rounded-xl flex justify-center `}
            >
              {/* <div
              className={`h-8 w-[1px] ${
                passed ? "bg-gray-500" : "bg-gray-300"
              } -translate-y-13 ${
                passed ? "group-hover:-translate-y-10" : ""
              } transition-all duration-300 ${
                step == 0 ? "hidden" : "absolute"
              }`}
            /> */}
            </div>
          </div>
        </div>
        <div className=" flex flex-col justify-start items-start">
          <Typography
            variant="body1"
            className={`text-sm text-start
            ${
              blocked
                ? "text-gray-300"
                : step <= highestStep || active
                ? "text-primary"
                : "text-gray-400"
            }
            ${active ? "font-extrabold" : "font-semibold"} h-5`}
          >
            Schritt {step + 1}
          </Typography>
          <Typography
            variant="h6"
            className={`font-semibold text-sm text-start  ${
              blocked
                ? "text-gray-300"
                : step <= highestStep || active
                ? "text-primary"
                : "text-gray-400"
            }`}
            sx={{ display: { xs: "none", md: "block" } }}
            style={{
              textAlign: "start",
              lineHeight: "16px",
            }}
          >
            {text}
          </Typography>
        </div>
      </div>
    </>
  );
}
