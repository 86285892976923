import { Box, Typography } from "@mui/material";
import { motion } from "framer-motion";
import React from "react";
import { Link } from "react-router-dom";
import theme from "../../theme";

interface ButtonProps {
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
  style?: React.CSSProperties;
  children: React.ReactNode;
  href?: string;
  onClick?: () => void;
  className?: string;
}

const baseStyle = {
  border: "none",
  fontWeight: 500,
  marginTop: "0px",
  margin: "0px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "50px",
  borderRadius: "5px",
  padding: "0 30px",
};

export default function PrimaryButton({
  type,
  disabled,
  style,
  children,
  href,
  onClick,
}: ButtonProps) {
  if (type == undefined) type = "button";

  const ActiveButton = () => (
    <motion.button
      data-testid="primary-button"
      onClick={onClick}
      type={type}
      style={{
        cursor: "pointer",
        ...baseStyle,
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        ...style,
      }}
      whileHover={{
        scale: 1.01,

        backgroundColor: theme.palette.primary.light,
      }}
      whileTap={{ scale: 1.0 }}
      transition={{ ease: "easeInOut", duration: 0.1 }}
    >
      <Typography variant="body1" component={"span"}>
        {children}
      </Typography>
    </motion.button>
  );

  const Button = () => {
    if (href)
      return (
        <Link to={href} style={{ textDecoration: "none" }}>
          <ActiveButton />
        </Link>
      );
    return <ActiveButton />;
  };

  return (
    <div data-testid="primary-button-wrapper">
      {disabled ? (
        <motion.button
          data-testid="primary-button"
          className="123"
          onClick={disabled ? () => null : onClick}
          style={{
            cursor: "",
            backgroundColor: "lightgray",
            color: "white",
            ...baseStyle,
            ...style,
          }}
        >
          <Typography variant="body1" component={"span"}>
            {children}
          </Typography>
        </motion.button>
      ) : (
        <Button />
      )}
    </div>
  );
}
export function PrimaryButtonV2({
  type,
  disabled,
  style,
  children,
  href,
  className,
  onClick,
}: ButtonProps) {
  if (type == undefined) type = "button";

  const ActiveButton = () => (
    <button
      className={`${className} w-full md:w-auto px-8 flex justify-center rounded-full items-center text-white bg-secondary_dark border-secondary_dark border-solid border py-2 cursor-pointer hover:bg-secondary hover:text-white hover:border-secondary`}
      data-testid="primary-button"
      onClick={onClick}
      type={type}
      style={{
        cursor: "pointer",
        ...style,
      }}
    >
      <Typography variant="body1" component={"span"}>
        {children}
      </Typography>
    </button>
  );

  const Button = () => {
    if (href)
      return (
        <Link to={href} style={{ textDecoration: "none" }}>
          <ActiveButton />
        </Link>
      );
    return <ActiveButton />;
  };

  return (
    <div data-testid="primary-button-wrapper">
      {disabled ? (
        <button
          data-testid="primary-button"
          className={`w-full md:w-auto px-8 flex justify-center rounded-full items-center bg-gray-200  text-gray-400 border border-gray-200 py-2 cursor-default`}
          onClick={
            disabled
              ? (e) => {
                  e.preventDefault();
                }
              : onClick
          }
          style={{ ...style }}
        >
          <Typography variant="body1" component={"span"}>
            {children}
          </Typography>
        </button>
      ) : (
        <Button />
      )}
    </div>
  );
}

export function PrimaryButtonMobile({
  type,
  disabled,
  style,
  children,
  href,
  onClick,
}: ButtonProps) {
  if (type == undefined) type = "button";

  const button = (
    <>
      <Box
        sx={{ display: { xs: "block", md: "none" } }}
        style={{
          position: "fixed",
          left: "0",
          bottom: "61px",
          width: "100vw",
          overflow: "hidden",
          zIndex: 100,
          backgroundColor: theme.palette.background.paper,
          padding: "10px",
          ...style,
        }}
      >
        <PrimaryButton
          disabled={disabled}
          style={{
            width: "calc(100vw - 20px)",
            height: "50px",
          }}
        >
          {children}
        </PrimaryButton>
      </Box>
    </>
  );
  if (href) {
    return (
      <Link to={href} style={{ textDecoration: "none" }}>
        {button}
      </Link>
    );
  }
  return button;
}

export function SecondaryButton({
  style,
  children,
  onClick,
  href,
}: ButtonProps) {
  const button = (
    <motion.div
      onClick={onClick}
      style={{
        ...baseStyle,
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        borderWidth: "1px",
        borderStyle: "solid",
        cursor: "pointer",
        ...style,
        height: "48px",
      }}
      whileHover={{
        scale: 1.01,
        boxShadow: "black 0 0 10px -5px",
        borderColor: theme.palette.primary.light,
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
      }}
      whileTap={{ scale: 1.0 }}
      transition={{ ease: "easeInOut", duration: 0.1 }}
    >
      <Typography variant="body1" component={"span"}>
        {children}
      </Typography>
    </motion.div>
  );
  if (href) {
    return (
      <Link
        to={href}
        style={{ textDecoration: "none" }}
        onClick={() => window.scrollTo(0, 0)}
      >
        {button}
      </Link>
    );
  }
  return button;
}

export function SecondaryButtonV2({
  style,
  children,
  onClick,
  href,
  disabled,
  className,
}: ButtonProps) {
  const button = (
    <div
      className={`${className} w-full md:w-auto px-8 flex justify-center items-center  text-primary font-medium border-solid border border-primary rounded-full py-2 hover:bg-slate-200 cursor-pointer ${
        disabled &&
        "bg-slate-200 border-slate-200 text-slate-300 cursor-default"
      }`}
      onClick={() => {
        if (!disabled) {
          onClick && onClick();
        }
      }}
    >
      <Typography variant="body1" component={"span"}>
        {children}
      </Typography>
    </div>
  );
  if (href) {
    return (
      <Link
        to={href}
        style={{ textDecoration: "none" }}
        onClick={() => window.scrollTo(0, 0)}
      >
        {button}
      </Link>
    );
  }
  return button;
}
