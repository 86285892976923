import React, { useContext, useEffect, useState } from "react";
import { InvestmentContextFull } from "../investment-process-full";
import { MultistepFormButtonsV2 } from "../../../../components/vertical-multistep-form/multistep-form-buttons";
import { AppContext } from "../../direct-router";
import API_CLIENT from "../../../../utility/api-client";
import {
  Amount,
  CURRENCY,
  Investment,
  Voucher,
} from "../../../../types/investment_types";
import { useHistory } from "react-router-dom";
import AmountUtils from "../../../../utility/amount-utils";
import HookFormTextField from "../../../../components/hook-form-inputs";
import { DocumentBoxWide } from "../../../../components/data-display/document-box";
import PdfPreviewer from "../../dashboard/pdf-preview-document";

type SummaryProps = {
  back: () => void;
  next: () => void;
};

export default function Summary({ back, next }: SummaryProps) {
  const investment_context = useContext(InvestmentContextFull);
  const app_context = useContext(AppContext);
  const investment = investment_context.investment;
  const history = useHistory();
  const [discount_code, setDiscountCode] = useState("");
  const [voucher, setVoucher] = useState<Voucher>();
  const [disagio, setDisagio] = useState<Amount>();

  useEffect(() => {
    if (voucher == undefined) return;
    if (voucher.valid == false) return;

    const voucher_amount_off = voucher.discount.amount_off;
    const voucher_percent_off = voucher.discount.percent_off;

    var investment_amount = investment?.amount;
    if (!investment_amount) return;
    var discount_total = "0";
    if (voucher_amount_off) {
      discount_total = (Number(voucher_amount_off) * 100).toString();
    } else if (voucher_percent_off) {
      discount_total = (
        investment_amount * Number(voucher_percent_off)
      ).toString();
    } else {
      discount_total = "0";
    }

    var disagio_obj = {
      amount: discount_total,
      decimals: 2,
      currency: CURRENCY.EUR,
    };

    setDisagio(disagio_obj);
  }, [voucher]);

  const submitInvestment = () => {
    window.scrollTo(0, 0);
    if (investment === null) return;
    investment_context.setLoading(true);
    API_CLIENT.makeBindingOfferV3(
      app_context.token,
      investment.id,

      (investment: Investment) => {
        (window as any).dataLayer.push({
          event: "investment-accepted",
          user_id: app_context.sub,
          "investment-amount": investment.amount,
        });
        investment_context.setInvestment(investment);
        history.push("/direct/dashboard?investment_id=" + investment.id);

        localStorage.setItem(`investment-amount-${investment.campaignId}`, "");
      },
      (error: string) => {
        console.log(error);
      },
      discount_code
    );
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    submitInvestment();
  };

  if (!investment) return null;

  let agio_amount = 0;
  let agio = investment.agio;
  if (agio) {
    agio_amount = Number(agio.amount) / Math.pow(10, 2);
  }
  let disagio_amount = 0;
  if (disagio) {
    disagio_amount = Number(disagio.amount) / Math.pow(10, 2);
  }

  const investment_amount_total =
    investment?.amount + agio_amount - disagio_amount;

  return (
    <div className="flex flex-col gap-8">
      <div className="grid gap-4">
        <p className="text-3xl font-headline font-medium">
          Zusammenfassung deiner Angaben
        </p>
      </div>
      <div className="grid gap-4">
        <p>
          Im Folgenden kannst du dir noch einmal alle Dokumente rund um das
          Produkt ansehen. Lies dir diese sorgfältig durch.
        </p>
        <div className="flex gap-2 flex-wrap">
          {investment_context.campaign?.documents.map((c, i) => (
            <PdfPreviewer url={c.location} name={c.name}></PdfPreviewer>
          ))}
        </div>
      </div>
      <div className="grid grid-cols-3 py-8">
        <div className="grid grid-cols-1 col-span-2 gap-4 pr-8">
          <div className="w-full flex justify-between">
            <p className="text-lg text-gray-500">Anlagebetrag</p>
            <p className="text-xl font-medium">
              {investment_context.amount?.toLocaleString("de-de") + " €"}
            </p>
          </div>
          <div className="w-full flex justify-between">
            <p className="text-lg text-gray-500">Agio</p>
            <p className="text-xl font-medium">
              {AmountUtils.toString(investment_context.investment?.agio, true)}
            </p>
          </div>
          {disagio ? (
            <div className="w-full flex justify-between">
              <p className="text-lg text-gray-500">Sonderkonditionen*</p>
              <p className="text-xl font-medium">
                - {AmountUtils.toString(disagio, true)}
              </p>
            </div>
          ) : null}
          <div className="w-full flex justify-between">
            <p className="text-lg text-gray-500">Überweisungsbetrag</p>
            <p className="text-xl font-medium">
              {investment_amount_total.toLocaleString("de-de") + " €"}
            </p>
          </div>
        </div>
        <div>
          <div className="border-l border-gray-200 h-full pl-4 flex flex-col gap-2 items-start">
            <p className="text-xs font-medium text-gray-500">
              Investitionscode
            </p>

            <input
              onChange={(e) => setDiscountCode(e.target.value)}
              className="px-2 py-1 border rounded-lg text-sm bg-white-50 w-full active::border-primary"
            ></input>
            {voucher?.valid == false ? (
              <p className="text-xs text-red-500 font-semibold pl-1">
                code nicht gültig
              </p>
            ) : null}
            <button
              onClick={() => {
                var c_id = investment_context.campaign?.cashlinkId;
                if (c_id == null) return;
                API_CLIENT.validateVoucherV2(
                  app_context.token,
                  discount_code,
                  c_id,
                  (voucher) => {
                    setVoucher(voucher);
                  },
                  (error) => {
                    setDisagio(undefined);
                    setVoucher(undefined);
                  }
                );
              }}
              className="hover:bg-black hover:text-white bg-gray-200 text-gray-700 px-3 py-1  text-xs font-medium rounded-lg"
            >
              Prüfen
            </button>
          </div>
        </div>
      </div>
      {disagio ? (
        <div>
          * Wir überweisen dir den Betrag, sobald der volle Investitionsbetrag
          inklusive Agio auf dem Konto eingegangen ist.
        </div>
      ) : null}
      <form onSubmit={handleSubmit}>
        <MultistepFormButtonsV2
          backFunction={() => back()}
          back={true}
          disabled={false}
          nextButtonText="Zur Unterschrift"
        />
      </form>
    </div>
  );
}
