import React, { useContext, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import BankAndTaxAIF from "../../../components/page-components/investment-process-aif/bank-and-tax-aif";
import InvestmentAmountAIF from "../../../components/page-components/investment-process-aif/investment-amount-aif";
import { TextField, Typography } from "@mui/material";
import { InvestmentSurveyAIF } from "../../../components/page-components/investment-process-aif/investment-survey-aif";
import MultistepForm from "../../../components/vertical-multistep-form/multistep-form";
import MultistepFormBody, {
  MultistepFormBodyV2,
} from "../../../components/vertical-multistep-form/multistep-form-body";
import MultistepFormNavigation, {
  MultistepFormNavigationV2,
} from "../../../components/vertical-multistep-form/multistep-form-navigation";
import MultistepFormStep, {
  MultistepFormStepV2,
} from "../../../components/vertical-multistep-form/multistep-form-step";
import VerticalStepper, {
  VerticalStepperV2,
} from "../../../components/vertical-multistep-form/vertical-stepper";
import { Campaign, Fund } from "../../../types/fund_types";
import {
  CreateInvestmentDTO,
  Investment,
  INVESTMENT_STATE,
  SelfDisclosure,
} from "../../../types/investment_types";
import LoadingScreen from "../../../components/loading-screen";
import { SecondaryButton } from "../../../components/input-elements/buttons";
import MultistepFormButtons from "../../../components/vertical-multistep-form/multistep-form-buttons";
import Footer from "../../../template/footer";
import FooterV2 from "../../../template/footer-v2";
import { PersonalData } from "./subpages/personal-data";
import InvestmentAmount from "./subpages/investment-amount";
import API_CLIENT from "../../../utility/api-client";
import queryString from "query-string";
import { AppContext } from "../direct-router";
import { MatchingDTO, USER_STATE } from "../../../types/generic_types";
import { SurveyFull } from "./subpages/survey";
import { useAuth0 } from "@auth0/auth0-react";
import TagManager from "react-gtm-module";
import { sha256 } from "js-sha256";
import BankAndTaxFull from "./subpages/bank-tax";
import { INVESTOR_TYPE } from "../../../types/onboarding_types";
import theme from "../../../theme";
import Summary from "./subpages/summary";
import Guide from "./subpages/guide";
import DocumentBox, {
  DocumentBoxWide,
} from "../../../components/data-display/document-box";
import PdfPreviewer from "../dashboard/pdf-preview-document";

interface InvestmentContextFull {
  fund: Fund | null;
  campaign: Campaign | null;
  investment: Investment | null;
  setInvestment: (investment: Investment | null) => void;
  last_investment: Investment | null | undefined;
  amount: number | null;
  setAmount: (n: number) => void;
  nextStep: () => void;
  prevStep: () => void;
  setLoading: (loading: boolean) => void;
  setForceRedraw: () => void;
}

export enum INVESTMENTPROCESS_STEP {
  ERROR = -1,
  AMOUNT = 0,
  PERSONAL_DATA = 1,
  SURVEY = 2,
  BANK = 3,
  SUMMARY = 4,
  SUCCESS = 5,
}

export const InvestmentContextFull = React.createContext<InvestmentContextFull>(
  {
    fund: null,
    campaign: null,
    investment: null,
    setInvestment: () => null,
    last_investment: null,
    amount: null,
    setAmount: () => null,
    nextStep: () => null,
    prevStep: () => null,
    setLoading: () => null,
    setForceRedraw: () => null,
  }
);

export default function InvestmentProcessFull() {
  const { isAuthenticated } = useAuth0();
  const app_context = useContext(AppContext);
  const user = app_context.user;
  const [loading, setLoading] = useState(true);
  const [investment, setInvestment] = useState<Investment | null>(null);
  const [investment_loading, setInvestmentLoading] = useState(true);
  const [active_step, setActiveStep] = useState(INVESTMENTPROCESS_STEP.AMOUNT);
  const [blockTo, setBlockTo] = useState(0);
  const [fund, setFund] = useState<Fund | null>(null);
  const [fund_loading, setFundLoading] = useState(true);
  const [campaign, setCampaign] = useState<Campaign | null>(null);

  const [highestStep, setHighestStep] = useState(1);
  const [last_investment, setLastInvestment] = useState<Investment | null>();
  const [last_investment_loading, setLastInvestmentLoading] = useState(false);
  const [force_redraw, setForceRedraw] = useState(true);
  const [personal_step_dirty, setPersonalStepDirty] = useState(false);
  const [bank_tax_step_dirty, setBankTaxStepDirty] = useState(false);
  var investment_in_creation = false;

  //queries
  const queries = queryString.parse(window.location.search);
  const campaign_id = queries.campaign ? queries.campaign.toString() : "";
  const fund_id = queries.fund ? queries.fund.toString() : "";

  let ls_amount: number | null = Number(
    localStorage.getItem(`investment-amount-${campaign_id}`)
  );
  if (ls_amount == 0) ls_amount = null;

  const [amount, setAmount] = useState<number | null>(ls_amount);

  const invalidateDirtySteps = () => {
    setPersonalStepDirty(false);
    setBankTaxStepDirty(false);
  };

  useEffect(() => {
    console.log("use effect");

    API_CLIENT.getFundNoAuth(
      fund_id,
      (fund) => {
        setFund(fund);
        var campaign = fund.cashlinkCampaigns.find(
          (campaign) => campaign.cashlinkId == campaign_id
        );
        if (campaign) {
          setCampaign(campaign);
        }
        setFundLoading(false);
      },
      (error) => console.log(error)
    );
    if (!isAuthenticated) {
      setInvestmentLoading(false);
      return;
    }
    API_CLIENT.getLastIncomingInvestment(
      app_context.token,
      user!.uid,
      (investment) => {
        setLastInvestment(investment);
        setLastInvestmentLoading(false);
      },
      () => {
        setLastInvestment(null);
        setLastInvestmentLoading(false);
        console.log("Init: No previous investment signed");
      }
    );
    API_CLIENT.getInvestmentsByCampaign(
      app_context.token,
      campaign_id,
      (investments: Investment[]) => {
        var activeInvestment = null;
        var commulatedInvestmentSize = 0;
        investments.forEach((investment) => {
          if (investment.state === INVESTMENT_STATE.PENDING) {
            activeInvestment = investment;
          }
          if (
            investment.state === INVESTMENT_STATE.ACCEPTED ||
            investment.state === INVESTMENT_STATE.WAITING_FOR_ACCEPTANCE ||
            investment.state === INVESTMENT_STATE.PAID ||
            investment.state === INVESTMENT_STATE.DELIVERED ||
            investment.state === INVESTMENT_STATE.KYC_PENDING ||
            investment.state === INVESTMENT_STATE.KYC_INVALID ||
            investment.state === INVESTMENT_STATE.KYC_INSUFFICIENT
          ) {
            commulatedInvestmentSize += investment.amount;
          }
        });

        setInvestment(activeInvestment);
        setInvestmentLoading(false);
      },
      () => {
        setInvestment(null);
        //setInvestmentLoading(false);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  useEffect(() => {
    console.log("use effect: amount changed");
    const cou = async () => {
      if (
        !investment_loading &&
        !fund_loading &&
        app_context.userState != null
      ) {
        createOrUpdateInvestment();
      }
    };
    cou();
  }, [amount]);

  useEffect(() => {
    const cou = async () => {
      if (user && !investment_loading) {
        console.log("create investment because no one exists");
        await createOrUpdateInvestment();
      }
    };
    cou();
    // if (
    //   fund &&
    //   campaign &&
    //   !investment_loading &&
    //   app_context.userState != null
    // ) {
    //   setLoading(false);
    // }
  }, [fund_loading, investment_loading, app_context.userState, user]);

  //##########
  useEffect(() => {
    console.log("investment changed:", investment);
  }, [investment]);

  useEffect(() => {
    console.log("investmentLoading changed:", investment_loading);
  }, [investment_loading]);

  useEffect(() => {
    console.log("fundLoading changed:", fund_loading);
  }, [fund_loading]);

  useEffect(() => {
    console.log("userState changed:", user);
  }, [user]);

  useEffect(() => {
    console.log("forceRedraw changed:", force_redraw);
  }, [force_redraw]);

  useEffect(() => {
    console.log("user changed:", user);
  }, [user]);

  useEffect(() => {
    console.log("amount changed:", amount);
  }, [amount]);
  //#######

  //this useEffect ensures that as soon as everything needed is loaded, the step is chosen
  useEffect(() => {
    if (
      !investment_loading &&
      !fund_loading &&
      app_context.userState != null &&
      !investment_in_creation
    ) {
      console.log("use effect: investment changed");
      //prevent choosing step to only force it once
      //if (amount != investment?.amount) return;
      chooseStep();
      setLoading(false);
    }
  }, [
    investment,
    investment_loading,
    fund_loading,
    app_context.userState,
    force_redraw,
    user,
    amount,
  ]);

  const chooseStep = () => {
    console.log("selecting step");
    console.log("personal step dirty: " + personal_step_dirty);
    let step = INVESTMENTPROCESS_STEP.AMOUNT;
    let highest_step = INVESTMENTPROCESS_STEP.AMOUNT;

    const user_legal =
      app_context.user?.investor_type == INVESTOR_TYPE.LEGAL_PERSON;
    const legal_person_missing =
      user?.legal_person == null || user.benefiting_persons.length == 0;
    const is_legal_onboarded =
      !(user_legal && legal_person_missing) || !user_legal;

    const is_personal_data = amount != null;
    const is_survey =
      is_personal_data &&
      (app_context.userState == USER_STATE.ONBOARDED_1 ||
        app_context.userState == USER_STATE.ONBOARDED_2) &&
      is_legal_onboarded;
    const is_survey_dirty = personal_step_dirty && is_survey;
    const is_bank_tax = is_survey && investment?.survey;
    const is_bank_tax_dirty = is_survey_dirty && investment?.survey;
    const is_summary =
      is_bank_tax && user?.bank_account && user?.taxInformation;
    const is_summary_dirty = bank_tax_step_dirty && is_summary;

    if (is_personal_data) {
      console.log("selecting step: amount is set");
      step = INVESTMENTPROCESS_STEP.PERSONAL_DATA;
      highest_step = INVESTMENTPROCESS_STEP.PERSONAL_DATA;
      setPersonalStepDirty(true);
    }
    if (is_survey) {
      console.log("selecting step: personal data are given");
      highest_step = INVESTMENTPROCESS_STEP.SURVEY;
    }
    if (is_survey_dirty) {
      console.log("selecting step: personal data are dirty");
      step = INVESTMENTPROCESS_STEP.SURVEY;
    }
    if (is_bank_tax) {
      console.log("selecting step: survey data are given");
      highest_step = INVESTMENTPROCESS_STEP.BANK;
      setBankTaxStepDirty(true);
    }
    if (is_bank_tax_dirty) {
      console.log("selecting step: survey data are dirty");

      step = INVESTMENTPROCESS_STEP.BANK;
    }
    if (is_summary) {
      console.log("selecting step: bank data are given");
      highest_step = INVESTMENTPROCESS_STEP.SUMMARY;
    }
    if (is_summary_dirty) {
      console.log("selecting step: bank data are dirty");
      step = INVESTMENTPROCESS_STEP.SUMMARY;
    }

    switch (step) {
      case INVESTMENTPROCESS_STEP.SURVEY:
        break;
    }

    if (user_legal) {
    }

    setStep(step, highest_step);
  };

  const setStep = (
    step: INVESTMENTPROCESS_STEP,
    highest_step: INVESTMENTPROCESS_STEP
  ) => {
    setActiveStep(step);
    setHighestStep(highest_step);
  };

  const nextStep = () => {
    let next_step = INVESTMENTPROCESS_STEP.ERROR;
    switch (active_step) {
      case INVESTMENTPROCESS_STEP.AMOUNT:
        next_step = INVESTMENTPROCESS_STEP.PERSONAL_DATA;
        break;
      case INVESTMENTPROCESS_STEP.PERSONAL_DATA:
        next_step = INVESTMENTPROCESS_STEP.SURVEY;
        break;
      case INVESTMENTPROCESS_STEP.SURVEY:
        next_step = INVESTMENTPROCESS_STEP.BANK;
        break;
      case INVESTMENTPROCESS_STEP.BANK:
        next_step = INVESTMENTPROCESS_STEP.SUMMARY;
        break;
      case INVESTMENTPROCESS_STEP.SUMMARY:
        next_step = INVESTMENTPROCESS_STEP.SUCCESS;
        // Handle the logic for the final step
        break;
      default:
        break;
    }
    var highest_step = highestStep;
    if (next_step > highestStep) highest_step = next_step;
    setStep(next_step, highest_step);
  };

  const prevStep = () => {
    let prev_step = INVESTMENTPROCESS_STEP.ERROR;
    switch (active_step) {
      case INVESTMENTPROCESS_STEP.SUCCESS:
        prev_step = INVESTMENTPROCESS_STEP.SUMMARY;
        break;
      case INVESTMENTPROCESS_STEP.SUMMARY:
        prev_step = INVESTMENTPROCESS_STEP.BANK;
        break;
      case INVESTMENTPROCESS_STEP.BANK:
        prev_step = INVESTMENTPROCESS_STEP.SURVEY;
        break;
      case INVESTMENTPROCESS_STEP.SURVEY:
        prev_step = INVESTMENTPROCESS_STEP.PERSONAL_DATA;
        break;
      case INVESTMENTPROCESS_STEP.PERSONAL_DATA:
        prev_step = INVESTMENTPROCESS_STEP.AMOUNT;
        break;
      default:
        break;
    }
    setStep(prev_step, highestStep);
  };

  const createOrUpdateInvestment = async () => {
    if (investment_loading) return;
    console.log("create or update investment");
    //check if user is logged in
    //   ==> if yes, continue
    //   ==> if not, do nothing
    if (!isAuthenticated) {
      console.log("user is not logged in");
      chooseStep();
      setLoading(false);
      return;
    }
    invalidateDirtySteps();
    //check if an investment exists
    if (investment != null) {
      console.log("investment already exists");
      console.log("checking, if amount changed");
      if (investment.amount != amount) {
        if (amount == null) return;
        setPersonalStepDirty(false);
        setBankTaxStepDirty(false);
        console.log("updating investment");
        var sd: SelfDisclosure = {
          self_disclosure_monthly_income: true,
          self_disclosure_net_worth_100k: true,
        };
        var createInvestmentDTO: CreateInvestmentDTO = {
          selfDisclosure: sd,
          couponCode: null,
          investmentKey: null,
        };
        investment_in_creation = true;
        API_CLIENT.putInvestmentAmount(
          createInvestmentDTO,
          app_context.token,
          amount,
          investment.id,
          (investment) => {
            console.log("updated investment");
            investment_in_creation = false;
            setInvestment(investment);
          },
          (error) => console.log(error),
          () => {},
          () => {}
        );
      } else {
        console.log("amount didnt change");
      }
    } else {
      console.log("investment hasen't been created yet");
      console.log("creating investment");

      var sd: SelfDisclosure = {
        self_disclosure_monthly_income: true,
        self_disclosure_net_worth_100k: true,
      };
      var createInvestmentDTO: CreateInvestmentDTO = {
        selfDisclosure: sd,
        couponCode: null,
        investmentKey: null,
      };
      if (amount == null || campaign == null) return;
      investment_in_creation = true;
      API_CLIENT.postInvestmentAmount(
        createInvestmentDTO,
        app_context.token,
        amount,
        campaign.cashlinkId,
        (investment) => {
          investment_in_creation = false;
          console.log("successfully created investment");
          var uData = app_context.matchingData;

          // var mData: MatchingDTO = {
          //   event: "CustomizeProduct",
          //   eventId: investment.id,
          //   sourceUrl: window.location.href,
          //   em: uData?.em,
          //   ph: uData?.ph,
          //   fn: uData?.fn,
          //   ln: uData?.ln,
          //   product: fund?.name,
          //   focus: fund?.fokus,
          //   type: fund?.type,
          //   value: investment.amount.toString(),
          //   currency: "EUR",
          // };

          //API_CLIENT.postMatching(app_context.token, mData);

          // TagManager.dataLayer({
          //   dataLayer: {
          //     event: "customize-product",
          //     event_id: investment.id,
          //     first_name: app_context.user?.natural_person.forename,
          //     last_name: app_context.user?.natural_person.surname,
          //     phone: uData?.ph,
          //     email: sha256(app_context.userEmail),
          //     product: fund?.name,
          //     focus: fund?.fokus,
          //     type: fund?.type,
          //     value: investment.amount,
          //     currency: "EUR",
          //   },
          // });
          // (window as any).dataLayer.push({
          //   event: "investment-created",
          //   "investment-amount": investment.amount,
          // });

          setInvestment(investment);
          setInvestmentLoading(false);
        },
        (error) => console.log(error),
        () => {},
        () => {}
      );
    }
    console.log("investment updated or created");

    //   ==> if yes, check if amount changed and update it regardingly
    //   ==> if not, create a new one
  };

  return (
    <InvestmentContextFull.Provider
      value={{
        fund: fund,
        campaign: campaign,
        investment: investment,
        amount: amount,
        last_investment: last_investment,
        setAmount: (amount: number | null) => {
          setAmount(amount);
        },
        setInvestment: (new_investment: Investment | null) => {
          setInvestment(new_investment);
        },
        nextStep: nextStep,
        prevStep: prevStep,
        setLoading: (loading) => setLoading(loading),
        setForceRedraw: () => {
          chooseStep();
          setLoading(false);
        },
      }}
    >
      <div className="flex justify-center">
        <div className="w-full max-w-6xl mx-6">
          <div className="flex items-center gap-8">
            <div className="grid gap-8 py-8 text-primary">
              <p className=" text-4xl font-headline">
                Dein Investment in den <b>{fund?.name}</b>
              </p>
              <div className="grid gap-4">
                <p className="text-lg font-body text-primary_light leading-6">
                  Schließe hier dein Investment in den {fund?.name} Fonds ab.
                  Kontaktiere uns gerne, falls Fragen aufkommen sollten.
                </p>
              </div>
              <div className="grid gap-1">
                <p className="text-sm font-medium text-primary">
                  Wichtige Dokumente zum Produkt:
                </p>
                <div className="flex justify-start gap-2">
                  {campaign?.documents.map((document) => (
                    <PdfPreviewer
                      url={document.location}
                      name={document.name}
                    ></PdfPreviewer>
                  ))}
                </div>
              </div>
            </div>
            <div>
              <img
                className="  opacity-100 right-0 top-0"
                src="https://ik.imagekit.io/inventure/App/static/Kopf_Kollage_transparent_300kb_m1T8l3Jai.png?updatedAt=1719315662234"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="h-[1px] w-full bg-primary_light bg-opacity-20" />
      <div className="flex justify-center ">
        <div className="w-full max-w-6xl md:mt-8 my-16">
          <div>
            <MultistepForm
              style={{ minHeight: "100vh", height: "fit-content" }}
            >
              <MultistepFormNavigationV2>
                <VerticalStepperV2
                  loading={loading}
                  blockTo={blockTo}
                  alignTop
                  totalSteps={5}
                  highestStep={highestStep}
                  onStepClick={(step) => {
                    if (step <= highestStep) setActiveStep(step);
                  }}
                  activeStep={active_step}
                  returnOnFirstStep={false}
                  labels={[
                    "Anlagebetrag festlegen",
                    "Persönliche Informationen",
                    "Kenntnisse und Erfahrungen",
                    "Bankverbindung und Steuerdaten",
                    "Zusammenfassung der Investitionsdaten",
                  ]}
                />
              </MultistepFormNavigationV2>

              <MultistepFormBodyV2 alignTop>
                {loading ? (
                  <LoadingScreen className="h-screen w-full" />
                ) : (
                  <div className="flex flex-col w-full mx-4">
                    <MultistepFormStepV2
                      disableAnimation
                      activeStep={active_step}
                      step={INVESTMENTPROCESS_STEP.AMOUNT}
                    >
                      <InvestmentAmount />
                    </MultistepFormStepV2>
                    <MultistepFormStepV2
                      disableAnimation
                      activeStep={active_step}
                      step={INVESTMENTPROCESS_STEP.PERSONAL_DATA}
                    >
                      <PersonalData back={prevStep} next={nextStep} />
                    </MultistepFormStepV2>
                    <MultistepFormStepV2
                      disableAnimation
                      activeStep={active_step}
                      step={INVESTMENTPROCESS_STEP.SURVEY}
                    >
                      <SurveyFull back={prevStep} next={nextStep} />
                    </MultistepFormStepV2>
                    <MultistepFormStepV2
                      disableAnimation
                      activeStep={active_step}
                      step={INVESTMENTPROCESS_STEP.BANK}
                    >
                      <BankAndTaxFull back={prevStep} next={nextStep} />
                    </MultistepFormStepV2>
                    <MultistepFormStepV2
                      disableAnimation
                      activeStep={active_step}
                      step={INVESTMENTPROCESS_STEP.SUMMARY}
                    >
                      <Summary back={prevStep} next={nextStep} />
                    </MultistepFormStepV2>
                    <div
                      className="underline cursor-pointer px-10 text-sm text-primary_light"
                      onClick={() => {
                        if (investment?.id) {
                          localStorage.removeItem(
                            `investment-amount-${campaign_id}`
                          );
                          API_CLIENT.abortInvestment(
                            app_context.token,
                            investment.id,
                            () => {
                              window.location.href = "/direct/dashboard";
                            },
                            () => null
                          );
                        }
                      }}
                    >
                      Investition abbrechen
                    </div>
                  </div>
                )}
              </MultistepFormBodyV2>
            </MultistepForm>
          </div>
        </div>
      </div>
    </InvestmentContextFull.Provider>
  );
}
/**
 *
 */
