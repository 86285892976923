import React, { useContext, useEffect, useState } from "react";
import { InvestmentContextFull } from "../investment-process-full";
import {
  PrimaryButtonV2,
  SecondaryButtonV2,
} from "../../../../components/input-elements/buttons";
import queryString from "query-string";
import API_CLIENT from "../../../../utility/api-client";
import { Campaign, Fund } from "../../../../types/fund_types";
import ReactPlayer from "react-player";

export default function Guide() {
  const [fund, setFund] = useState<Fund | null>(null);
  const [fund_loading, setFundLoading] = useState(true);
  const [campaign, setCampaign] = useState<Campaign | null>(null);
  const [video_playing, setVideoPlaying] = useState(false);

  //queries
  const queries = queryString.parse(window.location.search);
  const campaign_id = queries.campaign ? queries.campaign.toString() : "";
  const fund_id = queries.fund ? queries.fund.toString() : "";

  useEffect(() => {
    API_CLIENT.getFundNoAuth(
      fund_id,
      (fund) => {
        setFund(fund);
        var campaign = fund.cashlinkCampaigns.find(
          (campaign) => campaign.cashlinkId == campaign_id
        );
        if (campaign) {
          setCampaign(campaign);
        }
        setFundLoading(false);
      },
      (error) => console.log(error)
    );
  }, []);

  return (
    <div className="w-full h-5/6 e z-10 flex flex-col gap-32 p-8 max-w-7xl m mx-auto">
      <div className="grid grid-cols-2 gap-16 items-center">
        {campaign ? (
          <div className="flex flex-col gap-4">
            <p className="text-4xl font-medium font-headline">
              Deine Investition in den <b>{campaign?.name}</b>
            </p>
            <p className="text-xl text-gray-600">
              Hier kannst du in nur 10 Mintuen digital zeichnen. Damit du gut
              vorbereitet bist geben wir dir hier eine Übersicht über den
              Prozess.
            </p>
            <PrimaryButtonV2
              href={`/direct/investment?fund=${fund_id}&campaign=${campaign_id}`}
            >
              Jetzt Investieren
            </PrimaryButtonV2>
          </div>
        ) : (
          <div className="flex flex-col gap-8">
            <div className="h-8 w-full bg-gray-100 animate-pulse rounded-lg" />
            <div className="h-32 w-4/6 bg-gray-100 animate-pulse rounded-lg" />
          </div>
        )}
        <div>
          <div className="grid grid-cols-3 gap-4 bg-[#E2EAF2] rounded-xl items-center overflow-hidden">
            <div className="col-span-2">
              <div className="p-4 flex flex-col gap-2">
                <h2>Du hast Fragen oder benötigst Unterstützung?</h2>
                <p className="text-gray-500">
                  Wir helfen dir gerne weiter. Buche dir hier einen Termin
                </p>
                <a
                  href="https://meetings.hubspot.com/samuel-gassauer"
                  target="_blank"
                >
                  <SecondaryButtonV2 className="mt-8">
                    Termin buchen
                  </SecondaryButtonV2>
                </a>
              </div>
            </div>
            <div className="flex flex-col gap-4 p-4 text-white h-full bg-cover bg-center bg-[url(https://assets-global.website-files.com/603e16cc348981d0e613c257/6282151cad5b6b65b1b65a75_venture-capital-samuel-gassauer.jpg)]"></div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-8">
        <div className="w-full h-[1px] bg-[#E2EAF2]" />
        <div className="grid grid-cols-8 gap-8 items-center">
          <div className="flex flex-col col-span-2 text-lg font-semibold rounded-xl h-full">
            <div className="p-4 flex-grow flex flex-col gap-2">
              <p>1. Zeichnung</p>
              <p className="text-sm font-normal">
                In der digitalen Zeichnung wirst du angaben zu deiner Person,
                deiner Steueridentifikationsnummer und zu deiner Erfahrung
                machen.
              </p>
            </div>{" "}
            <div className="text-xs  px-4 py-2 bg-[#E2EAF2] rounded-lg">
              ~10 Minuten
            </div>
          </div>
          <div className="w-full h-full flex justify-center items-center">
            <img
              className="w-8 h-8"
              src="https://ik.imagekit.io/inventure/App/Icons/icons8-arrow-100_5Z3E4pr29.png?updatedAt=1688639616311"
            />
          </div>
          <div className="flex flex-col col-span-2 text-lg font-semibold rounded-xl h-full">
            <div className="p-4 flex-grow flex flex-col gap-2">
              <p>2. Bestätigung</p>
              <p className="text-sm font-normal">
                Hier wirst du Final noch einmal alle Dokumente einsehen und die
                Daten, welche du vorher anegegeben hast.
              </p>
            </div>
            <div className="text-xs  px-4 py-2 bg-[#E2EAF2] rounded-lg">
              ~10 Minuten
            </div>
          </div>
          <div className="w-full h-full flex justify-center items-center">
            <img
              className="w-8 h-8"
              src="https://ik.imagekit.io/inventure/App/Icons/icons8-arrow-100_5Z3E4pr29.png?updatedAt=1688639616311"
            />
          </div>
          <div className="flex flex-col col-span-2 text-lg font-semibold rounded-xl h-full">
            <div className="p-4 flex-grow flex flex-col gap-2">
              <p>3. Legitimation</p>
              <p className="text-sm font-normal">
                Sobald die Zeichnung bei uns eingegangen ist erhälst du von uns
                einen Link, mit dem du dich bequem bei der Deutschen Post Online
                oder in der Filiale legitimieren kannst.
              </p>
            </div>
            <div className="text-xs  px-4 py-2 bg-[#E2EAF2] rounded-lg">
              ~10 Minuten
            </div>
          </div>
        </div>
      </div>
      <div className="grid gap-8">
        <div>
          <h2>
            <b>Erklärvideo:</b> So sieht eine Investition über inVenture aus
          </h2>
          <p>
            In diesem Video könnt ihr euch im Vorfeld anschauen, wie eine
            Investition über unsere Onlineplattform ausschaut.
          </p>
        </div>
        <div className="relative pt-96 w-full aspect-video">
          <ReactPlayer
            style={{ position: "absolute", top: "0", left: "0" }}
            url="https://player.vimeo.com/video/969788054"
            playing={true}
            controls={true}
            width="100%"
            height="100%"
            config={{
              vimeo: {
                playerOptions: {
                  byline: false,
                  portrait: false,
                  title: false,
                  dnt: true,
                  autoplay: false,
                  muted: false,
                  autopause: true,
                },
              },
            }}
          />
        </div>
      </div>
      <div className="w-full h-[1px] bg-[#E2EAF2]" />
    </div>
  );
}
