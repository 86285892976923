import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import {
  PrimaryButtonV2,
  SecondaryButton,
  SecondaryButtonV2,
} from "../../components/input-elements/buttons";

export default function NotLoggedIn() {
  const { loginWithRedirect } = useAuth0();
  return (
    <div>
      <div className="w-full h-screen bg-cover  md:hidden ">
        <div className=" p-4 grid gap-8">
          <img
            className="h-56 w-full object-cover rounded-xl"
            src="https://ik.imagekit.io/inventure/App/static/marek-piwnicki-Hs5gkoGI0IQ-unsplash_JcZ_VaR9G.jpg?updatedAt=1721751297102"
          />
          <div className="grid gap-8">
            <p className="text-3xl font-headline">Werde Teil des Wandels</p>

            <div className="grid gap-4 ">
              <PrimaryButtonV2
                onClick={() =>
                  loginWithRedirect({
                    screen_hint: "signup",
                    appState: {
                      returnTo: `${window.location.pathname}${window.location.search}`,
                    },
                  })
                }
              >
                Account erstellen
              </PrimaryButtonV2>
              <SecondaryButtonV2
                onClick={() =>
                  loginWithRedirect({
                    appState: {
                      returnTo: `${window.location.pathname}${window.location.search}`,
                    },
                  })
                }
              >
                Login
              </SecondaryButtonV2>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-[90vh]  md:grid-cols-2 md:grid hidden text-primary">
        <div className="flex flex-col justify-center items-center">
          <div className=" w-10/12 flex flex-col gap-16">
            <p className="text-5xl italic font-headline ">
              Werde Teil des Wandels
            </p>
            <p className="text-2xl leading-5  text-gray-700 font-semibold">
              200 Start-ups.
              <br />
              <br />
              Ein Investment.
            </p>
            <div className="flex gap-4">
              <PrimaryButtonV2
                onClick={() =>
                  loginWithRedirect({
                    screen_hint: "signup",
                    appState: {
                      returnTo: `${window.location.pathname}${window.location.search}`,
                    },
                  })
                }
              >
                Account erstellen
              </PrimaryButtonV2>
              <SecondaryButtonV2
                onClick={() =>
                  loginWithRedirect({
                    appState: {
                      returnTo: `${window.location.pathname}${window.location.search}`,
                    },
                  })
                }
              >
                Login
              </SecondaryButtonV2>
            </div>
          </div>
        </div>
        <div className="w-full h-full  overflow-hidden p-4">
          <div className="w-full h-full overflow-hidden rounded-xl">
            <img
              className="w-full h-full object-cover"
              src="https://ik.imagekit.io/inventure/App/static/marek-piwnicki-Hs5gkoGI0IQ-unsplash_JcZ_VaR9G.jpg?updatedAt=1721751297102"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
