import React, { useState } from "react";
import {
  LocalizationMap,
  ScrollMode,
  Viewer,
  Worker,
} from "@react-pdf-viewer/core";
import { getFilePlugin } from "@react-pdf-viewer/get-file";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
// Import the localization file
import de_DE from "@react-pdf-viewer/locales/lib/de_DE.json";
import { zoomPlugin } from "@react-pdf-viewer/zoom";

interface PdfPreviewerProps {
  url: string;
  name: string;
}

export default function PdfPreviewer({ url, name }: PdfPreviewerProps) {
  // Create new plugin instance
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    toolbarPlugin: {},

    sidebarTabs: (defaultTabs) => [
      // Remove the attachments tab (\`defaultTabs[2]\`)
      //defaultTabs[0], // Bookmarks tab
      //defaultTabs[1], // Thumbnails tab
    ],
  });

  const getFilePluginInstance = getFilePlugin();

  const [open, setOpen] = useState(false);

  return (
    <div className="">
      <div
        onClick={() => {
          setOpen(false);
        }}
        className={`${
          open ? "fixed" : "hidden"
        } w-full h-full bg-black top-0 left-0 bg-opacity-80 flex items-center z-30`}
      >
        <div
          className="w-11/12 max-w-2xl  bg-white m-auto relative pointer-events-auto rounded-2xl overflow-hidden"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="absolute bottom-2 right-2 z-10">
            <div
              className="bg-secondary_dark text-white rounded-full z-10 px-4 py-2 cursor-pointer"
              onClick={() => {
                setOpen(false);
              }}
            >
              Schließen
            </div>
          </div>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <div className="h-[80vh] w-full ">
              <Viewer
                enableSmoothScroll={false}
                localization={de_DE as unknown as LocalizationMap}
                fileUrl={url}
                scrollMode={ScrollMode.Vertical}
                plugins={[
                  defaultLayoutPluginInstance,
                  // getFilePluginInstance,
                  // Register plugins
                ]}
              />
            </div>
          </Worker>
        </div>
      </div>
      <div
        className={`w-full border py-2 px-4 flex cursor-pointer items-center rounded-full`}
        onClick={() => setOpen(!open)}
      >
        <div className="flex gap-2 items-center">
          <img
            className="h-6"
            src="https://ik.imagekit.io/inventure/App/static/icons/icons8-pdf-96_kHg3vFVtI.png?updatedAt=1719325890652"
          />
          <p className="text-sm">{name}</p>
        </div>
        <div className="flex-1" />
        <div className="w-3 h-3 relative flex justify-center items-center"></div>
      </div>
    </div>
  );
}
