import { Amount } from "../types/investment_types";

export default abstract class AmountUtils {
  static toString(amount: Amount | undefined, noComma?: boolean): string {
    if (amount === undefined || amount === null) return "";
    var number = amount.amount;
    if (Number(number) == 0) return "";
    var numberLength = number.length;
    var decimals = amount.decimals;

    var dot_position = numberLength - decimals;
    var integer_value = number.slice(0, dot_position);
    var fractional_value = number.slice(dot_position, dot_position + 2);

    var amountString = `${Number(integer_value).toLocaleString(
      "de-de"
    )},${fractional_value} €`;

    if (noComma) {
      amountString = `${Number(integer_value).toLocaleString("de-de")} €`;
    }
    return amountString;
  }
}
