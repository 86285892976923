import React from "react";
import DoneIcon from "@mui/icons-material/Done";
import { INVESTMENT_STATE } from "../../types/investment_types";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import PaymentIcon from "@mui/icons-material/Payment";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { Chip } from "@mui/material";
import theme from "../../theme";

type InvestmentStatusChipProps = {
  state: INVESTMENT_STATE;
};

export default function InvestmentStatusChip({
  state,
}: InvestmentStatusChipProps) {
  const waitingForAcceptanceIcon = (
    <HourglassEmptyIcon style={{ color: "white" }} />
  );
  const acceptedIcon = <PaymentIcon style={{ color: "white" }} />;
  const paidIcon = <DoneIcon style={{ color: "white" }} />;
  const deliveredIcon = <AccountBalanceIcon style={{ color: "white" }} />;
  const rejectedIcon = <ClearRoundedIcon style={{ color: "white" }} />;

  var text = "Fehler";
  var icon = waitingForAcceptanceIcon;
  var color = "white";
  switch (state) {
    case INVESTMENT_STATE.WAITING_FOR_ACCEPTANCE:
      text = "In Bearbeitung";
      icon = waitingForAcceptanceIcon;
      color = theme.palette.primary.light;
      break;
    case INVESTMENT_STATE.KYC_INSUFFICIENT:
    case INVESTMENT_STATE.KYC_INVALID:
    case INVESTMENT_STATE.KYC_PENDING:
      text = "In Bearbeitung";
      icon = waitingForAcceptanceIcon;
      color = theme.palette.primary.light;
      break;
    case INVESTMENT_STATE.ACCEPTED:
      text = "Zahlung ausstehend";
      icon = acceptedIcon;
      color = theme.palette.primary.light;
      break;
    case INVESTMENT_STATE.PAID:
      text = "Bezahlt";
      icon = paidIcon;
      color = "#74ad7a";
      break;
    case INVESTMENT_STATE.DELIVERED:
      text = "Token gutgeschrieben";
      icon = deliveredIcon;
      color = "#74ad7a";
      break;
    case (INVESTMENT_STATE.KYC_INSUFFICIENT, INVESTMENT_STATE.REJECTION):
      text = "Investition abgelehnt";
      color = "#b55555";
      icon = rejectedIcon;
  }

  return (
    <Chip
      icon={icon}
      label={text}
      style={{
        backgroundColor: color,
        color: "white",
        padding: "0 9px 0 10px",
      }}
    />
  );
}

export function InvestmentStatusChipV2({ state }: InvestmentStatusChipProps) {
  const waitingForAcceptanceIcon = (
    <HourglassEmptyIcon style={{ color: "white" }} />
  );

  var text = "Fehler";

  var color = "white";
  var text_color = "black";
  switch (state) {
    case INVESTMENT_STATE.WAITING_FOR_ACCEPTANCE:
      text = "In Bearbeitung";
      color = "bg-orange-100";
      text_color = "text-orange-700";
      break;
    case INVESTMENT_STATE.SIGNATURE_PENDING:
      text = "Unterzeichnung ausstehend";
      color = "bg-orange-100";
      text_color = "text-orange-700";
      break;
    case INVESTMENT_STATE.KYC_INSUFFICIENT:
    case INVESTMENT_STATE.KYC_INVALID:
    case INVESTMENT_STATE.KYC_PENDING:
    case INVESTMENT_STATE.SIGNED:
      text = "In Bearbeitung";
      color = "bg-orange-100";
      text_color = "text-orange-700";

      break;
    case INVESTMENT_STATE.ACCEPTED:
      text = "Zahlung ausstehend";
      color = "bg-orange-100";
      text_color = "text-orange-700";
      break;
    case INVESTMENT_STATE.PAID:
      text = "Bezahlt";
      color = "bg-green-100";
      text_color = "text-green-700";

      break;
    case INVESTMENT_STATE.DELIVERED:
      text = "Eingebucht";
      color = "bg-green-100";
      text_color = "text-green-700";
      break;
    case (INVESTMENT_STATE.KYC_INSUFFICIENT, INVESTMENT_STATE.REJECTION):
      text = "Investition abgelehnt";

      color = "bg-red-100";
      text_color = "text-red-700";
  }

  return (
    <div
      className={`${color} ${text_color} text-center px-3 py-[4px] text-xs rounded-full shadow-xs w-fit font-medium`}
    >
      <p>{text}</p>
    </div>
  );
}
