import {
  Button,
  Box,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
  Select,
} from "@mui/material";
import { sha256 } from "js-sha256";
import React, { useState, useContext, useEffect } from "react";
import TagManager from "react-gtm-module";
import { BootstrapInput2 } from "../../../../components/hook-form-inputs";

import PrimaryButton, {
  PrimaryButtonV2,
  SecondaryButton,
  SecondaryButtonV2,
} from "../../../../components/input-elements/buttons";
import IvSwitch, {
  IvSwitchV2,
} from "../../../../components/input-elements/iv-switch";
import LoadingScreen from "../../../../components/loading-screen";
import AbortInvestment from "../../../../components/page-components/investment-process/abort-investment";
import MultistepFormButtons, {
  MultistepFormButtonsV2,
} from "../../../../components/vertical-multistep-form/multistep-form-buttons";
import { MatchingDTO } from "../../../../types/generic_types";
import {
  EXPERIENCE,
  KNOWLEDGE,
  SERVICE_KNOWLEDGE_OR_EXPERIENCE,
  INVESTMENT_COUNT,
  INVESTMENT_SIZE,
  WAIVER_TYPE,
  Investment,
  Survey,
} from "../../../../types/investment_types";
import API_CLIENT from "../../../../utility/api-client";
import { InvestmentContextAIF } from "../../../portal/investment-aif/investment-process-aif";
import { AppContext } from "../../direct-router";
import { InvestmentContextFull } from "../investment-process-full";

type InvestmentSurveyAIFProps = {
  back: () => void;
  next: () => void;
};

export function SurveyFull({ back, next }: InvestmentSurveyAIFProps) {
  //Context
  const app_context = useContext(AppContext);
  const investment_context = useContext(InvestmentContextFull);
  const fund = investment_context.fund;
  const campaign = investment_context.campaign;
  const investment = investment_context.investment;

  const no_answer_survey: Survey = {
    investmentexperience_investment_fund: EXPERIENCE.NO_ANSWER,
    investmentknowledge_investment_fund: KNOWLEDGE.NO_ANSWER,
    investmentexperience_money_market_funds: EXPERIENCE.NO_ANSWER,
    investmentknowledge_money_market_funds: KNOWLEDGE.NO_ANSWER,

    investmentexperience_bond: EXPERIENCE.NO_ANSWER,
    investmentknowledge_bond: KNOWLEDGE.NO_ANSWER,
    investmentexperience_share: EXPERIENCE.NO_ANSWER,
    investmentknowledge_share: KNOWLEDGE.NO_ANSWER,

    investmentexperience_participation_right: EXPERIENCE.NO_ANSWER,
    investmentknowledge_participation_right: KNOWLEDGE.NO_ANSWER,
    investmentexperience_unsecured_junior_debt: EXPERIENCE.NO_ANSWER,
    investmentknowledge_unsecured_junior_debt: KNOWLEDGE.NO_ANSWER,
    investmentexperience_crypto_currency: EXPERIENCE.NO_ANSWER,
    investmentknowledge_crypto_currency: KNOWLEDGE.NO_ANSWER,
    investmentexperience_pension_funds: EXPERIENCE.NO_ANSWER,
    investmentknowledge_pension_funds: KNOWLEDGE.NO_ANSWER,
    investmentexperience_fund_of_funds: EXPERIENCE.NO_ANSWER,
    investmentknowledge_fund_of_funds: KNOWLEDGE.NO_ANSWER,
    investmentexperience_stock_funds: EXPERIENCE.NO_ANSWER,
    investmentknowledge_stock_funds: KNOWLEDGE.NO_ANSWER,
    investmentexperience_participation: EXPERIENCE.NO_ANSWER,
    investmentknowledge_participation: KNOWLEDGE.NO_ANSWER,

    serviceexperience_independent: SERVICE_KNOWLEDGE_OR_EXPERIENCE.NO_ANSWER,
    serviceexperience_investment_consultancy:
      SERVICE_KNOWLEDGE_OR_EXPERIENCE.NO_ANSWER,
    serviceexperience_investment_management:
      SERVICE_KNOWLEDGE_OR_EXPERIENCE.NO_ANSWER,
    serviceexperience_investment_brokerage:
      SERVICE_KNOWLEDGE_OR_EXPERIENCE.NO_ANSWER,

    avgyearlytransactions: INVESTMENT_COUNT.NO_ANSWER,
    avgtransactionvalue: INVESTMENT_SIZE.NO_ANSWER,

    scoreKnowledgeExperience: 0,
    scoreTransactions: 0,
    scoreService: 0,
  };

  const empty_survey: Survey = {
    investmentexperience_investment_fund: EXPERIENCE.NO_ANSWER,
    investmentknowledge_investment_fund: KNOWLEDGE.NO_KNOWLEDGE,
    investmentexperience_money_market_funds: EXPERIENCE.NO_ANSWER,
    investmentknowledge_money_market_funds: KNOWLEDGE.NO_KNOWLEDGE,

    investmentexperience_bond: EXPERIENCE.NO_ANSWER,
    investmentknowledge_bond: KNOWLEDGE.NO_KNOWLEDGE,
    investmentexperience_share: EXPERIENCE.NO_ANSWER,
    investmentknowledge_share: KNOWLEDGE.NO_KNOWLEDGE,

    investmentexperience_participation_right: EXPERIENCE.NO_ANSWER,
    investmentknowledge_participation_right: KNOWLEDGE.NO_KNOWLEDGE,
    investmentexperience_unsecured_junior_debt: EXPERIENCE.NO_ANSWER,
    investmentknowledge_unsecured_junior_debt: KNOWLEDGE.NO_KNOWLEDGE,
    investmentexperience_crypto_currency: EXPERIENCE.NO_ANSWER,
    investmentknowledge_crypto_currency: KNOWLEDGE.NO_KNOWLEDGE,
    investmentexperience_pension_funds: EXPERIENCE.NO_ANSWER,
    investmentknowledge_pension_funds: KNOWLEDGE.NO_ANSWER,
    investmentexperience_fund_of_funds: EXPERIENCE.NO_ANSWER,
    investmentknowledge_fund_of_funds: KNOWLEDGE.NO_ANSWER,
    investmentexperience_stock_funds: EXPERIENCE.NO_ANSWER,
    investmentknowledge_stock_funds: KNOWLEDGE.NO_ANSWER,
    investmentexperience_participation: EXPERIENCE.NO_ANSWER,
    investmentknowledge_participation: KNOWLEDGE.NO_ANSWER,

    serviceexperience_independent:
      SERVICE_KNOWLEDGE_OR_EXPERIENCE.NO_KNOWLEDGE_OR_EXPERIENCE,
    serviceexperience_investment_consultancy:
      SERVICE_KNOWLEDGE_OR_EXPERIENCE.NO_KNOWLEDGE_OR_EXPERIENCE,
    serviceexperience_investment_management:
      SERVICE_KNOWLEDGE_OR_EXPERIENCE.NO_KNOWLEDGE_OR_EXPERIENCE,
    serviceexperience_investment_brokerage:
      SERVICE_KNOWLEDGE_OR_EXPERIENCE.NO_KNOWLEDGE_OR_EXPERIENCE,

    avgyearlytransactions: INVESTMENT_COUNT.NO_TRANS,
    avgtransactionvalue: INVESTMENT_SIZE.LOWER_3000,

    scoreKnowledgeExperience: 0,
    scoreTransactions: 0,
    scoreService: 0,
  };

  const [unsuitability_checkbox, setUnsuitabilityCheckbox] = useState(false);
  const [no_answer, setNoAnswer] = useState(false);
  const [survey_update, setSurveyUpdate] = useState(false);
  const [survey, setSurvey] = useState<Survey | null>(null);
  const [use_previous, setUsePrevious] = useState(false);
  const [loading_next_step, setLoadingNextStep] = useState(false);
  const [last_survey_done, setLastSurveyDone] = useState(false);
  const [survey_done, setSurveyDone] = useState(
    investment_context.investment?.survey != null
  );
  const [last_paid_investment_loaded, setLastPaidInvestmentLoaded] =
    useState(false);
  /**
   * ActiveStep
   * 0 = Survey
   * 1 = Survey Waiver Unsuitablilty
   * 2 = Survey Waiver No Answer
   */
  const [active_step, setActiveStep] = useState(0);

  useEffect(() => {
    const investment = investment_context.investment;
    window.scrollTo(0, 0);
    const survey_investment_score =
      investment?.survey?.scoreKnowledgeExperience;
    const survey_service_score = investment?.survey?.scoreService;
    const survey_transaction_score = investment?.survey?.scoreTransactions;

    const campaign_investment_score = campaign?.experienceAndKnowledgeScore;
    const campaign_service_score = campaign?.serviceScore;
    const campaign_transaction_score = campaign?.amountAndVolumeScore;

    var surveyValid = false;
    if (
      survey_investment_score &&
      survey_service_score &&
      survey_transaction_score &&
      campaign_investment_score &&
      campaign_service_score &&
      campaign_transaction_score
    ) {
      surveyValid =
        survey_investment_score >= campaign_investment_score &&
        survey_service_score >= campaign_service_score &&
        survey_transaction_score >= campaign_transaction_score;
    }
    if (investment_context.investment?.survey == null) {
      setSurvey(empty_survey);
    } else {
      setSurvey(investment_context.investment?.survey);
    }
  }, []);

  if (investment == null || campaign == null) {
    console.log("survey: investment or campaign are not set");
    console.log(investment, campaign);
    return null;
  }

  //API Calls
  const track = () => {
    var uData = app_context.matchingData;
    var mData: MatchingDTO = {
      event: "Wallet",
      eventId: investment.id,
      sourceUrl: window.location.href,
      em: uData?.em,
      ph: uData?.ph,
      fn: uData?.fn,
      ln: uData?.ln,
      product: fund?.name,
      focus: fund?.fokus,
      type: fund?.type,
      value: investment.amount.toString(),
      currency: "EUR",
    };
    API_CLIENT.postMatching(app_context.token, mData);

    TagManager.dataLayer({
      dataLayer: {
        event: "view-investment-process-wallet",
        event_id: investment.id,
        first_name: app_context.user?.natural_person.forename,
        last_name: app_context.user?.natural_person.surname,
        phone: uData?.ph,
        email: sha256(app_context.userEmail),
        product: fund?.name,
        focus: fund?.fokus,
        type: fund?.type,
        value: investment.amount,
        currency: "EUR",
      },
    });
  };

  const sendSurvey = (noAnswer?: boolean) => {
    console.log("send survey");
    window.scrollTo(0, 0);
    setLoadingNextStep(true);
    if (survey == null) return;
    const surveyToSend = noAnswer ? no_answer_survey : survey;
    API_CLIENT.putInvestmentSurvey(
      app_context.token,
      investment.id,
      surveyToSend,
      () => {
        console.log("survey score too low");
        investment.survey_waiver = null;
        investment_context.setInvestment(investment);
        setActiveStep(2);
        setLoadingNextStep(false);
      },
      () => {
        console.log("survey has empty fields");
        investment.survey_waiver = null;
        investment_context.setInvestment(investment);
        setActiveStep(1);
        setLoadingNextStep(false);
      },
      (investment) => {
        console.log("survey valid: updated investment");
        console.log(investment);
        track();
        investment_context.setInvestment(investment);
        next();
        setLoadingNextStep(false);
      },
      (error) => {
        setLoadingNextStep(false);
      }
    );
  };

  const sendNoAnswerWaiver = () => {
    console.log("sending no answer waiver");
    investment_context.setLoading(true);
    window.scrollTo(0, 0);

    setLoadingNextStep(true);
    API_CLIENT.putSurveyWaiver(
      app_context.token,
      investment.id,
      WAIVER_TYPE.NO_ANSWERS,
      (investment: Investment) => {
        track();
        investment_context.setInvestment(investment);
        investment_context.setLoading(false);
      },
      (error: string) => {}
    );
  };

  const UseLastInvestmentDialogue = () => {
    if (investment_context.last_investment == null) return null;
    return (
      <Box className="h-screen flex flex-col gap-4">
        <p className="text-3xl font-medium font-headline">
          Bitte trage hier deine Kenntnisse und Erfahrungen ein.
        </p>
        <Typography variant="body1">
          Du hast bei deiner letzten Investition bereits Deine Kenntnisse und
          Erfahrungen angegeben. Möchtest Du diese übernehmen?
        </Typography>
        <Grid container style={{ maxWidth: "500px" }}>
          <Grid item xs={12} sm={6} style={{ marginTop: "30px" }}>
            <PrimaryButtonV2
              style={{ width: "calc(100% - 20px)" }}
              onClick={() => {
                if (investment_context.last_investment) {
                  setSurvey(investment_context.last_investment.survey);
                }
                setLastSurveyDone(true);
              }}
            >
              Ja
            </PrimaryButtonV2>
          </Grid>
          <Grid item xs={12} sm={6} style={{ marginTop: "30px" }}>
            <SecondaryButtonV2
              onClick={() => {
                setSurvey(empty_survey);
                setLastSurveyDone(true);
              }}
              style={{ width: "calc(100% - 20px)", padding: "0" }}
            >
              Erneut ausfüllen
            </SecondaryButtonV2>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const sendUnsuitabilityWaiver = () => {
    console.log("sending unsuitability waiver");
    window.scrollTo(0, 0);

    investment_context.setLoading(true);
    API_CLIENT.putSurveyWaiver(
      app_context.token,
      investment.id,
      WAIVER_TYPE.UNSUITABLE,
      (investment: Investment) => {
        investment_context.setInvestment(investment);
        track();
        investment_context.setLoading(false);
      },
      (error: string) => {
        console.log(error);
      }
    );
  };

  //Components
  const NoAnswerWaiver = () => {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          sendNoAnswerWaiver();
        }}
      >
        <div className="grid gap-16">
          <div className="grid gap-8">
            <p className="text-3xl font-medium font-headline">
              Bitte trage hier deine Kenntnisse und Erfahrungen ein.
            </p>
            <div>
              <p className="font-medium">Kenntnissnahme</p>
              <div className="bg-orange-100 text-orange-900 rounded-xl p-4">
                <p>
                  Die Angemessenheit beurteilt sich danach, ob du als
                  Anleger/-in über die erforderlichen Kenntnisse und Erfahrungen
                  verfügst, um die Risiken im Zusammenhang mit der Art der
                  Finanzinstrumente oder Wertpapierdienstleistungen angemessen
                  beurteilen zu können. In den von dir zurückgesendeten
                  Unterlagen hast du unvollständige oder im Wesentlichen keine
                  Angaben zu deinen bisherigen Erfahrungen und Kenntnissen im
                  Zusammenhang mit Wertpapiergeschäften gemacht. Dadurch sind
                  wir nicht in der Lage, unserer Pflicht der Prüfung der
                  Angemessenheit der Anlage nachzukommen.
                  <br />
                  <br />
                  Wir weisen dich daher eindringlich darauf hin, dass eine
                  Angemessenheitsprüfung bei fehlenden/unvollständigen Angaben
                  nicht möglich ist. Du musst daher selbst entscheiden, ob die
                  Wertpapiere für dich angemessen sind. Insbesondere solltest du
                  dich mit den Risiken und Gegebenheiten der Wertpapiere
                  vertraut machen.
                  <br />
                  <br />
                  Eine ausführliche Darstellung der mit der Zeichnung
                  verbundenen Risiken befindet sich im Abschnitt
                  „Risikofaktoren“ des für das jeweilige Angebot maßgeblichen
                  Basisinformationsblatts. Solltest du dennoch von einer
                  Zeichnung Abstand nehmen wollen, kannst du Dein 14-tägiges
                  Widerrufsrecht geltend machen.
                </p>
              </div>
            </div>
          </div>
          <div>
            <MultistepFormButtonsV2
              nextButtonText="Bestätigen"
              disabled={false}
              back
              backFunction={() => setActiveStep(0)}
            />
          </div>
        </div>
      </form>
    );
  };

  const UnsuitabilityWaiver = () => {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (unsuitability_checkbox) sendUnsuitabilityWaiver();
        }}
      >
        <div className="grid gap-8">
          <p className="text-3xl font-serif font-medium font-headline">
            Bitte trage hier deine Kenntnisse und Erfahrungen ein.
          </p>
          <div>
            <div>
              <p className="font-medium">Kenntnisnahme</p>
              <p>
                Die Auswertung der von dir gemachten Angaben zu bisherigen
                Kenntnisse und Erfahrungen bei Wertpapieren, Vermögensanlagen
                und Kapitalmarktprodukten ergab, dass das hier angebotene
                Produkt bzw. die Höhe deiner Zeichnung nicht dem auf Basis
                deiner Angaben erstellten Anlageprofil entspricht. Gemäß deiner
                Angaben verfügst Du über zu wenig Kenntnisse und Erfahrungen, um
                die mit dem Erwerb des hier angebotenen Produkts verbundenen
                Risiken ausreichend beurteilen zu können, d.h. deine Zeichnung
                entspricht nicht deinem bisherigen Anlageprofil.
              </p>
              <div className="py-4 px-4 my-4 bg-orange-100 text-orange-700 rounded-xl font-medium">
                <p>
                  Bitte informiere dich noch einmal in dem
                  Basisinformationsblatt und den Risikohinweisen über die
                  Ausgestaltung und Risikostruktur des Produktes.
                </p>
              </div>
            </div>
            <Typography variant="body1" style={{ textAlign: "justify" }}>
              Wenn du dich entscheidest, das Produkt dennoch zu zeichnen, bitten
              wir dich, Folgendes zu bestätigen:
            </Typography>
          </div>
          <Grid
            item
            xs={12}
            sx={{
              flexDirection: {
                xs: "column-reverse",
                md: "row",
              },
              alignItems: {
                xs: "flex-start",
                md: "center",
              },
            }}
            style={{
              marginBottom: "50px",
              display: "flex",
            }}
          >
            <IvSwitchV2
              sx={{
                minWidth: {
                  xs: "200px",
                  md: "100px",
                },
                maxWidth: {
                  xs: "",
                  md: "100px",
                },
                marginTop: {
                  xs: "20px",
                  md: "0px",
                },
              }}
              style={{
                marginRight: "20px",
              }}
              defaultValue={unsuitability_checkbox}
              onChange={(checked) => {
                setUnsuitabilityCheckbox(checked);
              }}
            />
            <Typography variant="body1">
              Ich bin darüber informiert, dass aufgrund der von mir angegebenen
              Kenntnisse und bisherigen Erfahrungen bei Wertpapieren,
              Vermögensanlagen und sonstigen Kapitalmarktprodukten die Zeichnung
              der Kapitalanlage nicht meinem Anlageprofil entspricht.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "50px",
            }}
          >
            <MultistepFormButtonsV2
              nextButtonText="Bestätigen"
              disabled={!unsuitability_checkbox}
              back
              backFunction={() => setActiveStep(0)}
            />
          </Grid>
        </div>
      </form>
    );
  };

  const Survey = () => {
    if (!survey) return null;
    return (
      <Grid container>
        {use_previous ? (
          <Grid item xs={12}>
            <Typography
              variant="h5"
              style={{ marginBottom: "50px", marginTop: "50px" }}
            >
              Einschätzung Deiner Erfahrungen als investierende Person​
            </Typography>
            <Typography variant="body1">
              Du hast bereits Angaben für dieses Investment gemacht. Möchtest Du
              diese beibehalten?
            </Typography>
            <Grid container spacing={0} style={{ margin: "0px 0 0px 0" }}>
              <Grid item xs={12} md={6}>
                <Button
                  variant="contained"
                  onClick={() => {
                    //onSkip(investment);
                  }}
                  style={{
                    width: "calc(100% - 40px)",
                    marginTop: "10px",
                  }}
                >
                  Überspringen
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  onClick={() => {
                    setUsePrevious(false);
                  }}
                  style={{
                    width: "calc(100% - 40px)",
                    marginTop: "10px",
                  }}
                >
                  Angaben Überprüfen
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <div className="grid gap-4">
            <p className="grid gap-4">
              <p className="text-3xl font-serif font-medium font-headline">
                Bitte trage hier deine Kenntnisse und Erfahrungen ein.
              </p>
              <p className="text-base">
                Gemäß Wertpapierhandelsgesetz (§ 63 Abs. 5 WpHG) sind wir
                verpflichtet, deine Kenntnisse und Erfahrungen bezüglich
                verschiedener Produktklassen wie Wertpapieren, Vermögensanlagen
                und sonstigen Kapitalmarktprodukten abzufragen.
                <br />
                <br />
                Mittels der Antworten können wir für dich prüfen, ob die
                Zeichnung dieser Kapitalanlage deinen Kenntnissen und
                Erfahrungen aus deinem bisherigen Anlageverhalten entspricht.
                Die Beantwortung dieser Fragen ist jedoch freiwillig. Machst du
                keine oder unvollständige Angaben, können wir die Angemessenheit
                des jeweiligen Kapitalmarktproduktes nicht für dich prüfen.
                Daher solltest du aus eigenem Interesse Angaben machen.
              </p>
            </p>
            <div className="w-full">
              <div className="flex gap-4 items-center">
                <div className="w-48">
                  <IvSwitchV2
                    defaultValue={no_answer}
                    onChange={(event) => {
                      window.scrollTo(0, 0);
                      setNoAnswer(event);
                    }}
                  />
                </div>
                <p className="w-full">Ich möchte keine Angaben machen</p>
              </div>
            </div>
            <Grid item xs={12}>
              {no_answer ? (
                <form
                  className="mt-8"
                  onSubmit={(e) => {
                    e.preventDefault();
                    sendSurvey(true);
                  }}
                >
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <Grid item xs={12}></Grid>
                    </Grid>
                    <MultistepFormButtonsV2
                      nextButtonText="Bestätigen"
                      disabled={false}
                      back={false}
                      backFunction={() => null}
                    />
                  </Grid>
                </form>
              ) : (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    console.log(investment);
                    sendSurvey();
                  }}
                >
                  <div className="grid gap-16" style={{ overflow: "hidden" }}>
                    <div></div>
                    <div className="grid gap-8">
                      <p className="text-xl font-medium">
                        Erfahrungen mit Kapitalmarktprodukten
                      </p>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-16 gap-y-8">
                        <div className="w-full">
                          {/**
                          <Grid container
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginBottom: "0px",
                            }}
                          >
                            <Grid item xs={false} md={5}></Grid>
                            <Grid item xs={6} md={3}>   <Typography
                              variant="body1"
                              style={{
                                width: "25%",
                                minWidth: "100px",
                                fontWeight: 500,
                                textAlign: "left",
                                marginLeft: "5px"
                              }}
                            >
                              Kenntnisse
                            </Typography></Grid>
                            <Grid item xs={6} md={4}> <Typography
                              variant="body1"
                              style={{
                                width: "60%",
                                fontWeight: 500,
                                textAlign: "left",
                                marginLeft: "5px",
                              }}
                            >
                              Erfahrungen
                            </Typography></Grid>
                           
                         
                           
                          </Grid>
                           */}
                          <KnowledgeAndExperienceSelect
                            defaultValueKnowledge={
                              survey.investmentknowledge_pension_funds
                            }
                            defaultValueExperience={
                              survey.investmentexperience_pension_funds
                            }
                            question="Rentenfonds"
                            onChangeKnowledge={(newvalue) => {
                              survey.investmentknowledge_pension_funds =
                                newvalue;
                              setSurvey(survey);
                              setSurveyUpdate(!survey_update);
                            }}
                            onChangeExperience={(newvalue) => {
                              survey.investmentexperience_pension_funds =
                                newvalue;
                              setSurvey(survey);
                              setSurveyUpdate(!survey_update);
                            }}
                          />
                        </div>
                        <div>
                          <KnowledgeAndExperienceSelect
                            defaultValueKnowledge={
                              survey.investmentknowledge_bond
                            }
                            defaultValueExperience={
                              survey.investmentexperience_bond
                            }
                            question="Anleihen"
                            onChangeExperience={(newvalue) => {
                              survey.investmentexperience_bond = newvalue;
                              setSurvey(survey);
                              setSurveyUpdate(!survey_update);
                            }}
                            onChangeKnowledge={(newvalue) => {
                              survey.investmentknowledge_bond = newvalue;
                              setSurvey(survey);
                              setSurveyUpdate(!survey_update);
                            }}
                          />
                        </div>
                        <div>
                          <KnowledgeAndExperienceSelect
                            defaultValueKnowledge={
                              survey.investmentknowledge_share
                            }
                            defaultValueExperience={
                              survey.investmentexperience_share
                            }
                            question="Aktien"
                            onChangeKnowledge={(newvalue) => {
                              survey.investmentknowledge_share = newvalue;
                              setSurvey(survey);
                              setSurveyUpdate(!survey_update);
                            }}
                            onChangeExperience={(newvalue) => {
                              survey.investmentexperience_share = newvalue;
                              setSurvey(survey);
                              setSurveyUpdate(!survey_update);
                            }}
                          />
                        </div>
                        <div>
                          <KnowledgeAndExperienceSelect
                            defaultValueKnowledge={
                              survey.investmentknowledge_money_market_funds
                            }
                            defaultValueExperience={
                              survey.investmentexperience_money_market_funds
                            }
                            question="Geldmarkt(nahe)fonds"
                            onChangeKnowledge={(newvalue) => {
                              survey.investmentknowledge_money_market_funds =
                                newvalue;
                              setSurvey(survey);
                              setSurveyUpdate(!survey_update);
                            }}
                            onChangeExperience={(newvalue) => {
                              survey.investmentexperience_money_market_funds =
                                newvalue;
                              setSurvey(survey);
                              setSurveyUpdate(!survey_update);
                            }}
                          />
                        </div>
                        <div>
                          <KnowledgeAndExperienceSelect
                            defaultValueKnowledge={
                              survey.investmentknowledge_participation
                            }
                            defaultValueExperience={
                              survey.investmentexperience_participation
                            }
                            question="Beteiligungen"
                            onChangeKnowledge={(newvalue) => {
                              survey.investmentknowledge_participation =
                                newvalue;
                              setSurvey(survey);
                              setSurveyUpdate(!survey_update);
                            }}
                            onChangeExperience={(newvalue) => {
                              survey.investmentexperience_participation =
                                newvalue;
                              setSurvey(survey);
                              setSurveyUpdate(!survey_update);
                            }}
                          />
                        </div>
                        <div>
                          <KnowledgeAndExperienceSelect
                            defaultValueKnowledge={
                              survey.investmentknowledge_stock_funds
                            }
                            defaultValueExperience={
                              survey.investmentexperience_stock_funds
                            }
                            question="Aktienfonds"
                            onChangeKnowledge={(newvalue) => {
                              survey.investmentknowledge_stock_funds = newvalue;
                              setSurvey(survey);
                              setSurveyUpdate(!survey_update);
                            }}
                            onChangeExperience={(newvalue) => {
                              survey.investmentexperience_stock_funds =
                                newvalue;
                              setSurvey(survey);
                              setSurveyUpdate(!survey_update);
                            }}
                          />{" "}
                        </div>
                        <div>
                          <KnowledgeAndExperienceSelect
                            defaultValueKnowledge={
                              survey.investmentknowledge_fund_of_funds
                            }
                            defaultValueExperience={
                              survey.investmentexperience_fund_of_funds
                            }
                            question="Dachfonds / Gemischte Fonds"
                            onChangeKnowledge={(newvalue) => {
                              survey.investmentknowledge_fund_of_funds =
                                newvalue;
                              setSurvey(survey);
                              setSurveyUpdate(!survey_update);
                            }}
                            onChangeExperience={(newvalue) => {
                              survey.investmentexperience_fund_of_funds =
                                newvalue;
                              setSurvey(survey);
                              setSurveyUpdate(!survey_update);
                            }}
                          />{" "}
                        </div>

                        {/* <div>
                        <KnowledgeAndExperienceSelect
                          defaultValueKnowledge={
                            survey.investmentknowledge_crypto_currency
                          }
                          defaultValueExperience={
                            survey.investmentexperience_crypto_currency
                          }
                          question="Virtuelle Währungen"
                          onChangeKnowledge={(newvalue) => {
                            survey.investmentknowledge_crypto_currency =
                              newvalue;
                            setSurvey(survey);
                            setSurveyUpdate(!survey_update);
                          }}
                          onChangeExperience={(newvalue) => {
                            survey.investmentexperience_crypto_currency =
                              newvalue;
                            setSurvey(survey);
                            setSurveyUpdate(!survey_update);
                          }}
                        />{" "}
                      </div> */}
                      </div>
                    </div>

                    <div className="grid gap-8">
                      <div>
                        <p className="text-xl font-medium">
                          Erfahrungen mit Wertpapierdienstleistungen
                        </p>
                      </div>
                      <div>
                        <ServiceKnowledgeAndExperienceSelect
                          defaultValue={
                            survey.serviceexperience_investment_consultancy
                          }
                          question="Wie viel Erfahrung hast Du mit der Investition in Wertpapiere über eine Anlageberatung?"
                          onChange={(newvalue) => {
                            survey.serviceexperience_investment_consultancy =
                              newvalue;
                            setSurvey(survey);
                            setSurveyUpdate(!survey_update);
                          }}
                        />
                      </div>
                      <div>
                        <ServiceKnowledgeAndExperienceSelect
                          defaultValue={
                            survey.serviceexperience_investment_brokerage
                          }
                          question="Wie viel Erfahrung hast Du mit der Investition in Wertpapiere über eine Anlagevermittlung?"
                          onChange={(newvalue) => {
                            survey.serviceexperience_investment_brokerage =
                              newvalue;
                            setSurvey(survey);
                            setSurveyUpdate(!survey_update);
                          }}
                        />
                      </div>
                      <div>
                        <ServiceKnowledgeAndExperienceSelect
                          defaultValue={
                            survey.serviceexperience_investment_management
                          }
                          question="Wie viel Erfahrung hast Du mit der Investition in Wertpapiere über eine Vermögensverwaltung?"
                          onChange={(newvalue) => {
                            survey.serviceexperience_investment_management =
                              newvalue;
                            setSurvey(survey);
                            setSurveyUpdate(!survey_update);
                          }}
                        />
                      </div>
                      <div>
                        <ServiceKnowledgeAndExperienceSelect
                          defaultValue={survey.serviceexperience_independent}
                          question="Wie viel Erfahrung hast Du mit der eigenständigen, beratungsfreien Investition in Wertpapiere?"
                          onChange={(newvalue) => {
                            survey.serviceexperience_independent = newvalue;
                            setSurvey(survey);
                            setSurveyUpdate(!survey_update);
                          }}
                        />
                      </div>
                    </div>
                    <div className="grid gap-8">
                      <div>
                        <p className="text-xl font-medium">
                          Häufigkeit und Volumen Deiner Transaktionen
                        </p>
                      </div>
                      <div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          <div>
                            <p className="text-md">
                              Wie viele Investitionen tätigst Du pro Jahr?
                            </p>
                          </div>
                          <div>
                            <Select
                              className="w-full"
                              native
                              variant="outlined"
                              input={<BootstrapInput2 />}
                              defaultValue={survey.avgyearlytransactions}
                              onChange={(event) => {
                                survey.avgyearlytransactions = event.target
                                  .value as INVESTMENT_COUNT;
                                setSurvey(survey);
                                setSurveyUpdate(!survey_update);
                              }}
                              inputProps={{
                                name: "age",
                                id: "age-native-simple",
                              }}
                            >
                              <option value={INVESTMENT_COUNT.NO_TRANS}>
                                Keine Investitionen
                              </option>
                              <option value={INVESTMENT_COUNT.LOWER_5_TRANS}>
                                Bis zu 5 Investitionen
                              </option>
                              <option value={INVESTMENT_COUNT.LOWER_10_TRANS}>
                                Bis zu 10 Investitionen
                              </option>
                              <option
                                value={INVESTMENT_COUNT.MORE_THAN_10_TRANS}
                              >
                                Über 10 Investitionen
                              </option>
                            </Select>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          <div>
                            <p>
                              Wie hoch ist der durchschnittliche Gegenwert der
                              Transaktionen aus der vorherigen Frage?​
                            </p>
                          </div>
                          <div>
                            <Select
                              className="w-full"
                              native
                              variant="outlined"
                              input={<BootstrapInput2 />}
                              defaultValue={survey.avgtransactionvalue}
                              onChange={(event) => {
                                survey.avgtransactionvalue = event.target
                                  .value as INVESTMENT_SIZE;
                                setSurvey(survey);
                                setSurveyUpdate(!survey_update);
                              }}
                              inputProps={{
                                name: "age",
                                id: "age-native-simple",
                              }}
                            >
                              <option value={INVESTMENT_SIZE.LOWER_3000}>
                                Bis 3.000€
                              </option>
                              <option
                                value={INVESTMENT_SIZE.BETWEEN_3000_AND_5000}
                              >
                                Zwischen 3.000€ und 5.000€
                              </option>
                              <option
                                value={INVESTMENT_SIZE.BETWEEN_5000_AND_10000}
                              >
                                Zwischen 5.000€ und 10.000€
                              </option>
                              <option value={INVESTMENT_SIZE.OVER_10000}>
                                Mehr als 10.000€
                              </option>
                            </Select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <MultistepFormButtonsV2
                        nextButtonText="Bestätigen"
                        disabled={false}
                        back={true}
                        backFunction={back}
                      />
                    </div>
                  </div>
                </form>
              )}
            </Grid>
          </div>
        )}
      </Grid>
    );
  };

  const SurveyDone = () => {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          investment_context.nextStep();
        }}
        className="grid gap-8"
      >
        <p className="text-3xl font-headline font-medium">
          Bitte trage hier deine Kenntnisse und Erfahrungen ein.
        </p>
        <div className="bg-orange-100 text-orange-700 p-4 rounded-lg flex flex-col items-start gap-2">
          <div>
            <p className="text-xs">Angaben überarbeiten</p>
            <b>Du hast für dieses Investment bereits angaben gemacht.</b>{" "}
            Möchtest du diese überarbeiten?
          </div>
          <p
            onClick={() => setSurveyDone(false)}
            className="rounded-lg border border-orange-700 px-4 py-2 cursor-pointer text-sm"
          >
            Jetzt überarbeiten
          </p>
        </div>
        <MultistepFormButtonsV2
          back={true}
          backFunction={() => investment_context.prevStep()}
          disabled={false}
        />
      </form>
    );
  };

  return (
    <>
      {survey ? (
        <>
          {loading_next_step ? (
            <LoadingScreen className="w-full h-screen" />
          ) : investment_context.last_investment != null &&
            investment_context.investment?.survey == null &&
            !last_survey_done ? (
            <UseLastInvestmentDialogue />
          ) : survey_done ? (
            <SurveyDone />
          ) : active_step == 0 ? (
            <Survey />
          ) : active_step == 1 ? (
            <NoAnswerWaiver />
          ) : active_step == 2 ? (
            <UnsuitabilityWaiver />
          ) : null}
        </>
      ) : null}
    </>
  );
}

function KnowledgeAndExperienceSelect({
  defaultValueKnowledge,
  defaultValueExperience,
  question,
  onChangeKnowledge,
  onChangeExperience,
}: KnowledgeAndExperienceSelectProps) {
  const defaultValueKnowledgeBool =
    defaultValueKnowledge === KNOWLEDGE.NO_ANSWER ||
    defaultValueKnowledge === KNOWLEDGE.NO_KNOWLEDGE
      ? false
      : true;
  const returnExperience = (checked: boolean) =>
    checked ? KNOWLEDGE.KNOWLEDGE : KNOWLEDGE.NO_KNOWLEDGE;
  const [experience, setExperience] = useState(EXPERIENCE.NO_ANSWER);

  useEffect(() => {
    setExperience(defaultValueExperience);
  }, []);
  return (
    <div>
      <div>
        <div>
          <div>
            <p>{question}</p>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-2">
          <div>
            <div>
              <p className="text-sm text-gray-700">Kenntnisse</p>
              <IvSwitchV2
                defaultValue={defaultValueKnowledgeBool}
                onChange={(e) => {
                  onChangeKnowledge(returnExperience(e));
                }}
              ></IvSwitchV2>
            </div>
          </div>
          <div>
            <div className="w-full">
              <p className="text-sm text-gray-700">Erfahrungen</p>
              <Select
                className="w-full"
                native
                variant="outlined"
                input={<BootstrapInput2 />}
                defaultValue={defaultValueExperience}
                onChange={(event) => {
                  setExperience(event.target.value as EXPERIENCE);
                  onChangeExperience(event.target.value as EXPERIENCE);
                }}
                inputProps={{
                  name: "age",
                  id: "age-native-simple",
                }}
              >
                <option value={EXPERIENCE.NO_ANSWER}>Keine</option>
                <option value={EXPERIENCE.ZERO_TO_THREE_YEARS}>
                  {"< 3 Jahre"}
                </option>
                <option value={EXPERIENCE.THREE_TO_FIVE_YEARS}>
                  {"3 - 5 Jahre"}
                </option>
                <option value={EXPERIENCE.GREATER_THAN_FIVE_YEARS}>
                  {"> 5 Jahre"}
                </option>
              </Select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ServiceKnowledgeAndExperienceSelect({
  defaultValue,
  question,
  onChange,
}: ServiceKnowledgeAndExperienceSelectProps) {
  const [selected, setSelected] = useState(
    SERVICE_KNOWLEDGE_OR_EXPERIENCE.NO_ANSWER
  );

  useEffect(() => {
    setSelected(defaultValue);
  }, []);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div>
        <p>{question}</p>
      </div>
      <div>
        <Select
          className="w-full"
          native
          variant="outlined"
          input={<BootstrapInput2 />}
          defaultValue={defaultValue}
          onChange={(event) => {
            setSelected(event.target.value as SERVICE_KNOWLEDGE_OR_EXPERIENCE);
            onChange(event.target.value as SERVICE_KNOWLEDGE_OR_EXPERIENCE);
          }}
          inputProps={{
            name: "age",
            id: "age-native-simple",
          }}
        >
          <option
            value={SERVICE_KNOWLEDGE_OR_EXPERIENCE.NO_KNOWLEDGE_OR_EXPERIENCE}
          >
            Keine Erfahrung
          </option>
          <option value={SERVICE_KNOWLEDGE_OR_EXPERIENCE.ZERO_TO_THREE_YEARS}>
            Bis 3 Jahre Erfahrung
          </option>
          <option value={SERVICE_KNOWLEDGE_OR_EXPERIENCE.THREE_TO_FIVE_YEARS}>
            Zwischen 3 und 5 Jahre Erfahrung
          </option>
          <option
            value={SERVICE_KNOWLEDGE_OR_EXPERIENCE.GREATER_THAN_FIVE_YEARS}
          >
            Über 5 Jahre Erfahrung
          </option>
        </Select>
      </div>
    </div>
  );
}

type KnowledgeAndExperienceSelectProps = {
  defaultValueKnowledge: KNOWLEDGE;
  defaultValueExperience: EXPERIENCE;
  question: string;
  onChangeExperience: (value: EXPERIENCE) => void;
  onChangeKnowledge: (value: KNOWLEDGE) => void;
};

type ServiceKnowledgeAndExperienceSelectProps = {
  defaultValue: SERVICE_KNOWLEDGE_OR_EXPERIENCE;
  question: string;
  onChange: (value: SERVICE_KNOWLEDGE_OR_EXPERIENCE) => void;
};
