import React, { useEffect } from "react";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import {
  HookFormIBANFieldV2,
  HookFormSwitchV2,
  HookFormTextFieldV2,
  HookFormUppercaseFieldV2,
} from "../../../../components/hook-form-inputs";
import LoadingScreen from "../../../../components/loading-screen";
import { MultistepFormButtonsV2 } from "../../../../components/vertical-multistep-form/multistep-form-buttons";
import API_CLIENT from "../../../../utility/api-client";
import { AppContext } from "../../direct-router";
import { InvestmentContextFull } from "../investment-process-full";
import { INVESTOR_TYPE } from "../../../../types/onboarding_types";
import {
  AdditionalTaxCountry,
  BankAndTax,
  LEGAL_ENTITY_TYPE,
} from "../../../../types/inventure-user_types";

type BankAndTaxFullProps = {
  back: () => void;
  next: () => void;
};

export default function BankAndTaxFull(props: BankAndTaxFullProps) {
  const investment_context = useContext(InvestmentContextFull);
  const app_context = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const user = app_context.user;
  const [tins, setTins] = useState<AdditionalTaxCountry[]>();

  var ownr =
    user?.natural_person?.forename + " " + user?.natural_person?.surname;
  if (user?.investor_type == INVESTOR_TYPE.LEGAL_PERSON && user?.legal_person) {
    var type = "GmbH";
    if (user.legal_person.legal_entity_type == LEGAL_ENTITY_TYPE.UG)
      type = "UG";
    ownr = user.legal_person.company_name + " " + type;
  }

  const {
    control,
    formState,
    getValues,
    formState: { errors, isValid },
    handleSubmit,
    watch,
  } = useForm<any>({ mode: "onChange" });

  const hide_crs = watch("tax_germany_only");
  const no_us_taxation = watch("no_us_taxation");

  function submit(data: BankAndTax) {
    tins && (data.fatca_additional_tax_countries = JSON.stringify(tins));
    window.scrollTo(0, 0);
    investment_context.setLoading(true);

    API_CLIENT.putBankAndTax(
      app_context.token,
      data,
      (user) => {
        app_context.updateUser(user);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  if (loading) {
    return <LoadingScreen className="w-full h-screen" />;
  }

  return (
    <div className="flex flex-col items-start justify-start min-h-screen">
      <form onSubmit={handleSubmit(submit)} className="grid gap-16">
        <div className="grid gap-8">
          <div className="grid gap-4">
            <p className="text-3xl font-headline font-medium">
              Bitte gib hier deine Bankdaten und Steuerdaten ein
            </p>
          </div>

          <div className="grid gap-8 ">
            <div>
              <p className="text-xl font-medium">Auszahlungskonto</p>
              <div className="grid grid-cols-2 gap-4">
                <div className="col-span-2">
                  <p className="text-sm">
                    Aus rechtlichen Gründen darf der Kontoinhaber nicht vom
                    Nutzer abweichen.
                  </p>
                </div>
                <div className="text-xs col-span-2">
                  <div>Kontoinhaber</div>
                  <p className="text-base text-gray-800">{ownr}</p>
                </div>

                <HookFormUppercaseFieldV2
                  defaultValue={app_context.user?.bank_account?.bank}
                  required
                  control={control}
                  name={"bank"}
                  error={errors.bic != null}
                  label={"Bank*"}
                />

                <HookFormUppercaseFieldV2
                  defaultValue={app_context.user?.bank_account?.bic}
                  required
                  control={control}
                  name={"bic"}
                  error={errors.bic != null}
                  label={"BIC*"}
                />
                <div className="col-span-2">
                  <HookFormIBANFieldV2
                    label="IBAN"
                    defaultValue={app_context.user?.bank_account?.iban}
                    control={control}
                    name={"iban"}
                    required
                    error={errors.iban != null}
                  ></HookFormIBANFieldV2>
                </div>
              </div>
            </div>

            <div className="grid gap-4">
              <p className="text-xl font-medium">Steuernummer</p>
              <HookFormTextFieldV2
                label="Finanzamt"
                control={control}
                defaultValue={app_context.user?.taxInformation?.tax_office}
                name={"tax_office"}
                required
                error={errors.tin != null}
              ></HookFormTextFieldV2>
              <HookFormTextFieldV2
                label="Steueridentifikationsnummer"
                control={control}
                defaultValue={
                  app_context.user?.taxInformation?.tax_identification_number
                }
                name={"tin"}
                required
                error={errors.tin != null}
              ></HookFormTextFieldV2>
              <HookFormTextFieldV2
                label="Steuernummer"
                control={control}
                defaultValue={app_context.user?.taxInformation?.tax_number}
                name={"tax_number"}
                required
                error={errors.tin != null}
              ></HookFormTextFieldV2>
            </div>
            <div className="grid gap-8">
              <p className="text-xl font-medium">Steuerliche Bestätigung</p>
              <div className="">
                <p className="text-sm">
                  Ich bin Staatsangehöriger der Vereinigten Staaten von Amerika
                  und/oder in den Vereinigten Staaten von Amerika steuerlich
                  ansässig:
                </p>
                <div className="flex">
                  <HookFormSwitchV2
                    name="no_us_taxation"
                    control={control}
                    label=""
                    defaultValue={no_us_taxation}
                    error={false}
                  />
                </div>
              </div>
              <div className="">
                <p className="text-sm">
                  Ich bin natürliche Person und ausschließlich in der
                  Bundesrepublik Deutschland steuerlich ansässig und gelte auch
                  in keinem anderen Land als steuerlich ansässig.
                </p>
                <div className="flex">
                  <HookFormSwitchV2
                    name="tax_germany_only"
                    control={control}
                    label=""
                    defaultValue={
                      user?.taxInformation?.tax_germany_only ?? true
                    }
                    error={false}
                  />
                </div>
              </div>
            </div>
            {!hide_crs ? (
              <ForeignTins onTinChange={(tins) => setTins(tins)} />
            ) : null}
          </div>
        </div>
        <MultistepFormButtonsV2
          backFunction={() => investment_context.prevStep()}
          back={true}
          disabled={!formState.isValid || no_us_taxation == true}
        />
      </form>
    </div>
  );
}

interface ForeignTinsProps {
  onTinChange?: (tins: AdditionalTaxCountry[]) => void;
}

export function ForeignTins({ onTinChange }: ForeignTinsProps) {
  const app_context = useContext(AppContext);
  var tins_string =
    app_context.user?.taxInformation?.fatca_additional_tax_countries;
  var tins: AdditionalTaxCountry[] = tins_string && JSON.parse(tins_string);
  const [foreign_tins, setForeignTins] = useState<AdditionalTaxCountry[]>(
    tins ? tins : []
  );
  const [new_tin, setNewTin] = useState("");
  const [new_tin_country, setNewTinCountry] = useState("");

  const addTin = () => {
    if (new_tin == "" || new_tin_country == "") return;
    var new_tax_country: AdditionalTaxCountry = {
      tin: new_tin,
      country: new_tin_country,
    };
    setForeignTins([...foreign_tins, new_tax_country]);
    setNewTin("");
    setNewTinCountry("");
  };

  const deleteTin = (index: number) => {
    const new_tins = foreign_tins.filter((item, i) => i !== index);
    setForeignTins(new_tins);
  };

  useEffect(() => {
    onTinChange && onTinChange(foreign_tins);
  }, [foreign_tins]);

  return (
    <div id="facta-section" className="grid gap-8">
      <div>
        <div>
          <p className="text-sm">
            Listen bitte nachfolgend sämtliche Staaten auf, in denen du
            steuerlich ansässig bist oder in denen du als steuerlich ansässig
            giltst. Soweit vorhanden, gebe bitte auch die jeweils zugehörige
            Steueridentifikationsnummer (Taxpayer Identification Number „TIN“)
            dieser Staaten an. Gibt der betreffende Ansässigkeitsstaat keine TIN
            aus, gebe bitte an: „Der jeweilige Staat gibt keine TIN aus”.
            Zusätzlich beantwortest du bitte folgende Frage(n) bzgl. RBIs /CBIs
          </p>
        </div>
      </div>
      <table className="rounded-lg w-full grid gap-1">
        <tr className="w-full grid grid-cols-12 text-left">
          <th className="col-span-5">Steuerliche Ansässigkeit</th>
          <th className="col-span-5">Steueridentifikationsnummer (TIN)</th>
        </tr>
        {foreign_tins.length == 0 ? (
          <div className="bg-orange-100 px-4 py-1 rounded-md text-orange-700 mt-2">
            <p className="my-4 text-sm">
              Noch keine Steueridentifikationsnummer hinzugefügt.
            </p>
          </div>
        ) : (
          foreign_tins.map((tin, i) => (
            <div className="grid grid-cols-12">
              <p className="col-span-5">{tin.country}</p>
              <p className="col-span-5">{tin.tin}</p>
              <button
                type="button"
                className="bg-red-100  text-red-500 rounded-full p-1 px-4 text-sm col-span-2"
                onClick={() => deleteTin(i)}
              >
                Löschen
              </button>
            </div>
          ))
        )}
        <td></td>
      </table>
      <div className="grid grid-cols-2 gap-4">
        <div className="grid gap-1">
          <label className="text-xs  text-neutral-500">
            Steuerliche Ansässigkeit
          </label>
          <input
            value={new_tin_country}
            className="px-4 transition-all rounded-lg outline-none focus:border-[1.5px] hover:border-[1.5px] border-primary_light bg-[#E2EAF2] h-12"
            onChange={(e) => setNewTinCountry(e.target.value)}
          ></input>
        </div>
        <div className="grid gap-1">
          <label className="text-xs font- text-neutral-500">
            Steueridentifikationsnummer (TIN)
          </label>
          <input
            value={new_tin}
            className="px-4 transition-all rounded-lg outline-none focus:border-[1.5px] hover:border-[1.5px] border-primary_light bg-[#E2EAF2] h-12"
            onChange={(e) => setNewTin(e.target.value)}
          ></input>
        </div>
        <div className="flex">
          <button
            disabled={new_tin == "" || new_tin_country == ""}
            type="button"
            onClick={addTin}
            className="flex gap-2 disabled:text-slate-400 disabled:bg-slate-200 rounded-full px-4 h-8 bg-primary hover:bg-primary_light text-white justify-center items-center relative"
          >
            <div className="text-sm font-normal">Hinzufügen</div>
          </button>
        </div>
      </div>
    </div>
  );
}
